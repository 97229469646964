import React from "react";
import { ActivityIndicator } from "../../common/hoc";
import { useNavigation } from "react-auth-navigation";
import { CgImport } from "react-icons/cg";
import { APIS } from "../../../config/Api.config";
import { api } from "../../../helpers/Api.helper";

export const ShowTable = ({ title, data, loading }) => {
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;

  // console.log("List ",data)\

  //Calibrating Machine API is inconsistent with other API.

  const exportCalliberationCsv = async () => {
    let calibrationMachineResponse = await api(
      `${APIS.calibration_machineCSV}`
    );

    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "calibrationMachine.csv";
    link.href = URL.createObjectURL(
      new Blob([calibrationMachineResponse.data], { type: "text/csv" })
    );
    link.click();
  };

  const exportExpiringMachineCsv = async () => {
    let expiring_machineResponse = await api(`${APIS.expiring_machineCSV}`);

    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "expiringMachine.csv";
    link.href = URL.createObjectURL(
      new Blob([expiring_machineResponse.data], { type: "text/csv" })
    );
    link.click();
  };

  if (title === "GX machine that needs calibration in 6 months") {
    return (
      <div className="showTable">
        <table>
          <thead>
            <tr>
              <th align="left" colSpan={4}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h3 className="title">{title}</h3>
                  <div
                    onClick={exportCalliberationCsv}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <CgImport />
                  </div>
                </div>
              </th>
            </tr>
            <tr>
              <th align="left">Site Name</th>
              <th>Machine Code</th>
              <th>Needs Calibration in</th>
              <th align="right">Symbol No</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={4}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Loading
                  </div>
                </td>
              </tr>
            ) : data?.length > 0 ? (
              data.map((element) => {
                return (
                  <tr
                    key={`${element.code}${element.serial_no}`}
                    className="click"
                    onClick={() =>
                      window.open(
                        `https://gxmis.ntpmis.gov.np/#/gx-machine/edit/${element.machine}`
                      )
                    }
                  >
                    <td align="left">{element.site_name}</td>
                    <td>{element.code}</td>
                    <td>{element.calibration_date}</td>
                    <td align="right">{element.serial_no}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={4}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    No Data
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  } else if (title === "GX machine warranty expiring in 6 months") {
    return (
      <div className="showTable">
        <table>
          <thead>
            <tr>
              <th align="left" colSpan={4}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h3 className="title">{title}</h3>
                  <div
                    onClick={exportExpiringMachineCsv}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <CgImport />
                  </div>
                </div>
              </th>
            </tr>
            <tr>
              <th align="left">Site Name</th>
              <th>Machine Code</th>
              <th>Expiring in</th>
              <th align="right">Symbol No</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={4}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Loading
                  </div>
                </td>
              </tr>
            ) : data?.length > 0 ? (
              data.map((element) => {
                return (
                  <tr
                    key={element.id}
                    onClick={() =>
                      window.open(
                        `https://gxmis.ntpmis.gov.np/#/gx-machine/edit/${element.machine}`
                      )
                    }
                    className="click"
                  >
                    {/* https://gxmis.ntpmis.gov.np/#/gx-machine/edit/<machine_id></machine_id> */}
                    <td align="left">{element.site_name}</td>
                    <td>{element.code}</td>
                    <td>{element.warranty_end_date}</td>
                    <td align="right">{element.serial_no}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={4}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    No Data
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  } else {
    return (
      <div className="showTable">
        <h3 className="title">{title}</h3>
        {data?.length > 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxHeight: 300,
              overflowY: "auto",
            }}
          >
            {data.map((element, index) => {
              return (
                <p className="showTable-data" key={index}>
                  {element.site?.name}:{element.code}
                </p>
              );
            })}
          </div>
        ) : (
          <div>No data</div>
        )}
      </div>
    );
  }
};
