import React, { useState } from "react";
import { PieChart, Pie, Sector, Cell, Legend } from "recharts";
import { useNavigation } from "react-auth-navigation";
// const data = [
//   { name: 'Group A', value: 400 },
//   { name: 'Group B', value: 300 },
//   { name: 'Group C', value: 300 },
//   { name: 'Group D', value: 200 },
// ];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    // value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g style={{ cursor: "pointer" }}>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >
        {payload.value}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const PieChartBox = ({ data, chart }) => {
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const [activeIndex, setactiveIndex] = useState(0);

  const onPieEnter = (data, index) => {
    setactiveIndex(index);
  };

  const onPieClick = (data, index) => {
    console.log("dataclcik", data, index);
    // setactiveIndex(index)
    // if(data.payload.name==="1-2 days"){
    //   navigate('/machine-status/delay-one-two')
    // }

    switch (data.payload.name) {
      case "1-2 days":
        navigate("/machine-status/delay-one-two");
        break;
      case "3-4 days":
        navigate("/machine-status/delay-three-four");
        break;
      case "5-6 days":
        navigate("/machine-status/delay-five-six");
        break;
      case "More than 7 days":
        navigate("/machine-status/delay-seven");
        break;
      case "NOT DETECTED":
        navigate("/result?page=1&mtbResultType=MTB ND");
        break;
      case "NO RESULT":
        navigate("/result?page=1&mtbResultType=NO_RESULT");
        break;
      case "ERROR":
        navigate("/result?page=1&mtbResultType=ERROR");
        break;
      case "INVALID":
        navigate("/result?page=1&mtbResultType=INVALID");
        break;
      case "MTB+RIF I":
        navigate("/result?page=1&mtbResultType=MTB%2BRIF I");
        break;
      case "MTB+RIF R":
        navigate("/result?page=1&mtbResultType=MTB%2BRIF R");
        break;
      case "MTB+RIF S":
        navigate("/result?page=1&mtbResultType=MTB%2BRIF S");
        break;
      case "NEGATIVE":
        navigate("/covid-result?page=1&covidResultType=NEGATIVE");
        break;
      case "POSITIVE":
        navigate("/covid-result?page=1&covidResultType=POSITIVE");
        break;
      case "Enrolled":
        navigate("/sites?page=1&enroll=enrolledSite");
        break;
      case "Not Enrolled":
        navigate("/sites?page=1&enroll=notenrolledSite");
        break;
      case "Opened":
        navigate("/ticket?page=1&status=opened");

        break;
      case "Picked":
        navigate("/ticket?page=1&status=picked");
        // navigate({ pathname: "/ticket", state: "picked" });
        break;
      case "Working":
        navigate("/ticket?page=1&status=working");
        // navigate({ pathname: "/ticket", state: "working" });
        break;
      case "Solved":
        navigate("/ticket?page=1&status=solved");
        // navigate({ pathname: "/ticket", state: "solved" });
        break;
    }
  };
  // const COLORSSS = [
  //   "#cac428",
  //   "#ffd326",
  //   "#cb8427",
  //   "#cb2b3e",

  //   "#2aad27",
  //   "#313cd1",

  //   "#00C49F",
  //   "#fe2a00",
  //   "#538ce0",
  //   "#ff28ff",
  //   "#ccfc7e",
  //   "#FF8042",
  //   "#3b9403",
  // ];
  const getColor = () => {
    switch (chart) {
      case "machine-delay":
        return [
          "#cac428",
          "#ffd326",
          "#cb8427",
          "#cb2b3e",

          "#2aad27",
          "#313cd1",
        ];
      case "results":
        return [
          "#05c46b",
          "#890a0d",
          "#F79F1F",
          "#0fbcf9",
          "#EE5A24",
          "#A3CB38",
          "#005ff1",
        ];
      case "ticket":
        return ["#fe2a00", "#f2950f", "#ffd326", "#00C49F"];
      default:
        return [
          "#00C49F",
          "#fe2a00",
          "#538ce0",
          "#ff28ff",
          "#ccfc7e",
          "#FF8042",
          "#3b9403",
        ];
    }
  };
  const COLORS = getColor();
  return (
    <PieChart width={500} height={400}>
      <Pie
        activeIndex={activeIndex}
        activeShape={renderActiveShape}
        onClick={onPieClick}
        data={data}
        cx={200}
        cy={200}
        innerRadius={60}
        outerRadius={90}
        fill="#8884d8"
        dataKey="value"
        onMouseEnter={onPieEnter}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Legend verticalAlign="bottom" height={36} />
    </PieChart>
  );
};

export default PieChartBox;
