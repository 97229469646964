import React from "react";
import { useAuth } from "react-auth-navigation";
import { AnimatedBlock, useAnimatedValue, interpolate } from "react-ui-animate";
const ContainerBox = ({ title, children }) => {
  const { sideNavVisible } = useAuth();

  const sideMenuStableAnimation = useAnimatedValue(Number(sideNavVisible), {
    duration: 200,
  });
  return (
    <AnimatedBlock
      className="compwrapper-container"
      style={{
        paddingLeft: interpolate(
          sideMenuStableAnimation.value,
          [0, 1],
          [20, 320]
        ),
      }}
    >
      <div className="containerbox">
        <h3>{title && title.toUpperCase()}</h3>
        {children}
      </div>
    </AnimatedBlock>
  );
};

export default ContainerBox;
