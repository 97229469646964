import React from "react";
import { useNavigation } from "react-auth-navigation";

const TabItem = ({ active, path, name }) => {
  const { navigation } = useNavigation();
  return (
    <li className={active ? "tabAuth-list-item active" : "tabAuth-list-item"}>
      <div
        onClick={(e) => {
          e.preventDefault();
          navigation.navigate(`${path}`);
        }}
      >
        <div>{name}</div>
      </div>
    </li>
  );
};

export const TabAuth = ({ group }) => {
  const {
    navigation: { routes },
  } = useNavigation();
  console.log("routes", routes);
  return (
    <div className="tabAuth">
      {Object.keys(routes).map(
        (route, index) =>
          routes[route]?.props?.group === group && (
            <TabItem
              key={index}
              active={routes[route].active}
              path={routes[route].path}
              name={routes[route].name}
            />
          )
      )}
    </div>
  );
};
