import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { IoMdNotifications } from "react-icons/all";
import ContainerBox from "../common/hoc/ContainerBox.hoc";
import { APIS } from "../../config/Api.config";
import { api } from "../../helpers/Api.helper";
import { Button } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import Header from "../common/header/Header.common";
import { useNotification } from "../../context";
import moment from "moment";
const NotificationPage = () => {
  const { onNotificationHandler, onDeleteNotificationHandler } =
    useNotification();
  const [notificationList, setNotificationList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  const onPageChange = (event, newPage = 1) => {
    setPageNumber(newPage);
  };
  const fetch = async () => {
    let notification_response = await api(
      APIS.notification + `?page=${pageNumber}`
    );

    if (notification_response?.status === 200) {
      setNotificationList(notification_response?.data);
    }
  };

  useEffect(() => {
    fetch();
  }, [pageNumber]);

  const onReadHandler = async (id) => {
    let onReadResponse = await api(APIS.notification + id + "/", "PATCH", {
      read: true,
    });

    if (onReadResponse.status === 200) {
      fetch();
      onNotificationHandler();
      toast.success("Marked as read", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
  };
  console.log("useNotification()", useNotification());

  const onDeleteNotification = () => {
    onDeleteNotificationHandler(() => {
      setNotificationList([]);
    });
  };
  return (
    <ContainerBox>
      <Header title="Notification" />
      <div className="notification-container">
        <div className="notification">
          <div className="header">
            <div>Notifications</div>
            <div style={{ width: "100%", display: "flex" }}>
              <Pagination
                style={{
                  marginLeft: "auto",
                  marginTop: 20,
                  display: "inline-block",
                }}
                count={Math.ceil(notificationList?.count / 10)}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={onPageChange}
              />
            </div>
          </div>
          <hr />
          <Button onClick={onNotificationHandler}>refresh notification</Button>
          <Button
            onClick={onDeleteNotification}
            color="secondary"
            variant="contained"
          >
            Delete ALL notification
          </Button>
          {notificationList?.results?.length > 0 ? (
            notificationList.results.map((item, index) => {
              return (
                <div className="card" key={index}>
                  <div>
                    <div>{item.notification_text}</div>
                    <div
                      style={{
                        fontStyle: "italic",
                        fontSize: 12,
                      }}
                    >
                      {moment(item.created_at).format(
                        "dddd, MMM Do YYYY, h:mm:ss a"
                      )}
                    </div>
                  </div>
                  {!item.read && (
                    <div className="card-button">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => onReadHandler(item.id)}
                      >
                        Mark as read
                      </Button>
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "30vh",
                justifyContent: "center",
              }}
            >
              <IoMdNotifications size={50} color="grey" />
              <div>No notifications</div>
            </div>
          )}
        </div>
      </div>
    </ContainerBox>
  );
};

export default NotificationPage;
