import React, { useState } from "react";
import propTypes from "prop-types";

import { Modal } from "react-uicomp";

export const CustomModal = ({
  displayElement,
  children,
  onOutSideClickHandler = true,
  ...props
}) => {
  const [visible, setVisible] = useState(false);

  const onCloseModalHandler = () => {
    setVisible(false);
  };

  return (
    <>
      <div
        onClick={(e) => {
          e.preventDefault();
          setVisible(true);
        }}
      >
        {displayElement}
      </div>

      <Modal
        visible={visible}
        // onOutsideClick={() => !!onOutSideClickHandler && setVisible(false)}
        {...props}
      >
        {children({ onCloseModalHandler })}
      </Modal>
    </>
  );
};

CustomModal.propTypes = {
  displayElement: propTypes.element.isRequired,
  children: propTypes.func.isRequired,
};
