import React, { useEffect } from "react";
import { Auth, useNavigation } from "react-auth-navigation";

import ContainerBox from "../common/hoc/ContainerBox.hoc";
import Header from "../common/header/Header.common";
import { TabAuth } from "../common/tabAuth/tabAuth.common";

export const ReportPage = () => {
  const {
    navigation: { navigate },
    location: { pathname },
  } = useNavigation();
  useEffect(() => {
    if (pathname === "/report" || pathname === "/report/") {
      navigate("/report/site");
    }
  }, [navigate, pathname]);

  return (
    <ContainerBox>
      <Header title="Report" />
      <div className="notification-container">
        <div className="notification">
          <TabAuth group="Report" />
          <div style={{ marginTop: 20 }}>
            <Auth.Screens path="/report" />
          </div>

          {/* <SiteWiseRate /> */}
        </div>
      </div>
    </ContainerBox>
  );
};
