import React, { useState } from "react";
import { Button, FormControlLabel, Switch } from "@material-ui/core";
import moment from "moment";
import { Modal } from "react-uicomp";
import { UpdateCalliberationDate } from "./UpdateCalliberationDate";

export const SerialNumberData = ({
  changeSwitchSerial,
  serialData,
  // setMachineSerialDetail,
  changeSwitchModules,
}) => {
  const [machineSerialDetail, setMachineSerialDetail] = useState();
  const [warrantyDate, setWarrentyDate] = useState(
    serialData?.warranty_end_date
  );
  const [calliberationDate, setCalliberationDate] = useState(
    serialData?.calibration_date
  );

  return (
    <div className="each-module">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: 20,
          alignItems: "center",
          justifyContent: "space-between",
          background: "#dbdbdb",
          padding: 20,
          borderRadius: 4,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: 20,
            alignItems: "center",
          }}
        >
          <label>
            Serial : {serialData.serial_no} {`  `}
          </label>
          <FormControlLabel
            control={
              <Switch
                checked={serialData?.is_active}
                onChange={() => changeSwitchSerial()}
                color="primary"
              />
            }
            label={`Active`}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label>GX Warranty End Date </label>
            <div
              style={{
                fontSize: 18,
              }}
            >
              {warrantyDate ? moment(warrantyDate).format("YYYY-MM-DD") : "-"}{" "}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label>Calliberation Date </label>
            <div
              style={{
                fontSize: 18,
              }}
            >
              {calliberationDate
                ? moment(calliberationDate).format("YYYY-MM-DD")
                : "-"}{" "}
            </div>
          </div>
        </div>

        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={() => {
            setMachineSerialDetail(serialData);
          }}
        >
          Update Warrenty and Calliberation Date
        </Button>
      </div>
      {serialData?.modules?.map((element, moduleIndex) => {
        return (
          <div
            key={moduleIndex}
            className="each-module"
            style={{ marginLeft: 50 }}
          >
            Module : {element.serial_no} {`  `}
            <FormControlLabel
              control={
                <Switch
                  checked={element?.is_active}
                  onChange={() =>
                    changeSwitchModules(
                      moduleIndex
                      // serialIndex
                    )
                  }
                  color="primary"
                />
              }
              label={`Active`}
            />
          </div>
        );
      })}

      <Modal
        // visible={true}
        visible={!!machineSerialDetail}
        onClose={() => setMachineSerialDetail()}
        style={{
          width: "40vw",
          height: "58vh",
        }}
      >
        <UpdateCalliberationDate
          detail={serialData}
          onClose={() => setMachineSerialDetail()}
          {...{
            warrantyDate,
            setWarrentyDate,
            calliberationDate,
            setCalliberationDate,
          }}
        />
      </Modal>
    </div>
  );
};
