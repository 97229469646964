import React, { useState, useEffect } from "react";
import { useNavigation, useAuth } from "react-auth-navigation";
import ContainerBox from "../common/hoc/ContainerBox.hoc";
import { APIS } from "../../config/Api.config";
import { api } from "../../helpers/Api.helper";
import MaterialTable from "material-table";
import { Button, Paper } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import Header from "../common/header/Header.common";
import { Modal } from "react-uicomp";

import { useComponentDidUpdate, useDebounceValue } from "../../hooks";
const GxSitesPage = () => {
  const { navigation, location } = useNavigation();
  const { userRole } = useAuth();
  const { navigate, routes } = navigation;

  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const [pageNumber, setPageNumber] = useState(query.get("page") || 1);

  const [tableDataArray, setTableDataArray] = useState();

  const [searchValue, setSearchValue] = useState(query.get("site") || "");
  const searchSite = useDebounceValue(searchValue);

  // const [search, setSearch] = useState(false);

  const [enrolledType, setEnrolledType] = useState(query.get("enroll") || "");
  const [testType, setTestType] = useState(query.get("test") || "");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [siteId, setSiteId] = useState();

  const page = async (event, newPage = 1) => {
    setPageNumber(newPage);
    // search
    //   ? onsearchSite(searchValue, enrolledType, testType, false, newPage)
    //   : fetch(newPage);
  };

  const fetch = async (newPage = 1) => {
    setLoading(true);
    let site_result = await api(APIS.sites_response + `?page=${newPage}`);
    if (site_result?.status === 200) {
      setTableDataArray(site_result?.data);
      setLoading(false);
    }
    setLoading(false);
    // window.scrollTo(0, 0);
  };

  const onsearchSite = async (
    bodySearchValue,
    bodyEnrolledValue,
    bodytestType,
    newPage = 1
  ) => {
    setLoading(true);
    let site_result = await api(
      APIS.sites_response + `search/?page=${newPage}`,
      "POST",
      {
        name: bodySearchValue,
        enrolled_type: bodyEnrolledValue,
        site_type: bodytestType,
      }
    );
    if (site_result?.status === 200) {
      setTableDataArray(site_result?.data);
      setLoading(false);
    }

    // window.scrollTo(0, 0);
    // setTableDataArray(site_result?.data);
    setLoading(false);
  };

  useComponentDidUpdate(() => {
    if (pageNumber === 1) {
      searchSite || enrolledType || testType
        ? onsearchSite(searchSite, enrolledType, testType, 1)
        : fetch(1);

      let filter = "";
      if (!!enrolledType) {
        filter += `&enroll=${enrolledType}`;
      }
      if (!!searchSite) {
        filter += `&site=${searchSite}`;
      }

      if (!!testType) {
        filter += `&test=${testType}`;
      }

      navigate(`/sites?page=1${filter}`);
    } else {
      setPageNumber(1);
    }
  }, [enrolledType, searchSite, testType]);

  useEffect(() => {
    searchSite || enrolledType || testType
      ? onsearchSite(searchSite, enrolledType, testType, pageNumber)
      : fetch(pageNumber);

    let filter = "";
    if (!!enrolledType) {
      filter += `&enroll=${enrolledType}`;
    }
    if (!!searchSite) {
      filter += `&site=${searchSite}`;
    }

    if (!!testType) {
      filter += `&test=${testType}`;
    }
    console.log("filter", filter);
    navigate(`/sites?page=${pageNumber}${filter}`);
  }, [pageNumber]);

  const toEditpage = (datas) => {
    navigate({
      pathname: routes["GX Sites"].path + "/edit/" + datas.id,
      state: datas,
    });
  };
  const todeleteHandler = async () => {
    const req = await api(APIS.sites_response + siteId, "DELETE");
    window.location.reload();
  };
  const openDeleteModel = (datas) => {
    setVisible(true);
    setSiteId(datas.id);
    // console.log("rowdata",user)
  };

  return (
    <ContainerBox>
      <Header title="Gx Sites" />
      <Modal visible={visible} onClose={() => setVisible(false)}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          Are you sure you want to delete?
          <div
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="default"
              style={{ background: "red", color: "white" }}
              onClick={() => todeleteHandler()}
            >
              {" "}
              Delete{" "}
            </Button>
            <Button color="secondary" onClick={() => setVisible(false)}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      <div className="gxsite">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {(userRole === "1" || userRole === "3") && (
            <Button
              onClick={() => navigate(routes["Add GxSite"].path)}
              variant="contained"
              color="primary"
            >
              Add GX Site
            </Button>
          )}
          {userRole !== "1" && <div />}
          <div className="gxsite-select form">
            <form
              // onSubmit={handleSubmit(onsubmit)}
              style={{ display: "flex", rowGap: 10, columnGap: 10 }}
            >
              {(userRole === "1" || userRole === "2" || userRole === "3") && (
                <select
                  // name="enrolled"
                  // ref={register}
                  // defaultValue={location?.state}
                  value={enrolledType}
                  onChange={(e) => setEnrolledType(e.target.value)}
                >
                  <option value="">Enrolled Type</option>
                  <option value="All">All</option>
                  <option value="enrolledSite">Enrolled</option>
                  <option value="notenrolledSite">Not Enrolled</option>
                </select>
              )}

              {(userRole === "1" || userRole === "2" || userRole === "3") && (
                <select
                  name="tests"
                  onChange={(e) => setTestType(e.target.value)}
                >
                  <option value="">Test Type</option>
                  <option value="All">ALL</option>
                  <option value="mtb">MTB RIF</option>
                  <option value="covid">COVID</option>
                </select>
              )}

              <input
                // name="searchvalue"
                // ref={register()}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="Search"
              />

              <Button type="submit" variant="contained" color="primary">
                search
              </Button>
            </form>
          </div>
        </div>

        <MaterialTable
          title="GX SITE LIST"
          columns={[
            { title: "Name", field: "name" },
            { title: "Latitude", field: "latitude" },
            { title: "Longitude", field: "longitude" },
            { title: "Province", field: "province" },
            { title: "Contact Name", field: "contactName" },
            { title: "Contact Phone", field: "contactPhone" },
          ]}
          data={tableDataArray?.results}
          isLoading={loading}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          actions={[
            (rowData) => ({
              icon: "visibility",
              tooltip: "View Detail",
              onClick: (event, rowData) => {
                navigate(routes["GX Sites"].path + "/" + rowData.id);
              },
            }),
            (rowData) => {
              return (
                (userRole === "1" || userRole === "3") && {
                  icon: "edit",
                  tooltip: "Edit",
                  onClick: (event, rowData) => {
                    toEditpage(rowData);
                  },
                }
              );
            },
            (rowData) => {
              return (
                (userRole === "1" || userRole === "3") && {
                  icon: "delete",
                  tooltip: "Delete",
                  onClick: (event, rowData) => {
                    openDeleteModel(rowData);
                  },
                }
              );
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            emptyRowsWhenPaging: false,
            search: false,
            pageSizeOptions: [5, 10],
            pageSize: 10,
            paging: false,
            toolbar: false,
          }}
        />
        <div style={{ width: "100%", display: "flex" }}>
          <Pagination
            style={{
              marginLeft: "auto",
              marginTop: 20,
              display: "inline-block",
            }}
            count={Math.floor(tableDataArray?.count / 10) + 1}
            boundaryCount={1}
            variant="outlined"
            shape="rounded"
            onChange={page}
            page={Number(pageNumber)}
          />
        </div>
      </div>
    </ContainerBox>
  );
};

export default GxSitesPage;
