import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Card } from "@material-ui/core";

export const LineGraph = ({ data, graphType }) => {
  return (
    <BarChart
      width={800}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis padding={{ top: 10 }} tickCount={2} domain={[0, 1]} />
      <Tooltip content={<CustomTooltip {...{ graphType }} />} />
      {/* <Legend verticalAlign="top" wrapperStyle={{ lineHeight: "40px" }} /> */}
      <ReferenceLine y={0} stroke="#000" />
      {/* <Brush dataKey="date" height={30} stroke="#8884d8" /> */}
      <Bar dataKey="working" fill="#8884d8" />
    </BarChart>
  );
};

const CustomTooltip = ({ active, payload, label, graphType }) => {
  if (active && payload && payload.length) {
    return (
      <Card style={{ backgroundColor: "white", padding: 10 }}>
        <p className="label">{`${graphType} : ${payload[0].value}`}</p>
        <p
          className="intro"
          style={{ color: payload[0].value ? "black" : "red" }}
        >
          {payload[0].value
            ? `${graphType} was active on ${label}`
            : `${graphType} was inactive on ${label}`}
        </p>
      </Card>
    );
  }

  return null;
};
