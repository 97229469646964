// MARK: - getParsedUrl
export const getParsedUrl = (params) => {
  if (!params) {
    return "";
  }
  const newarr = Object.keys(params).filter((key) => {
    if (
      params[key] !== undefined &&
      params[key] !== null &&
      params[key] !== ""
    ) {
      return true;
    }
  });
  console.log("newarrnewarr", newarr);
  if (newarr.length < 1) return "";
  let urlString = "";
  newarr.forEach((key, index, array) => {
    if (
      params[key] !== undefined &&
      params[key] !== null &&
      params[key] !== ""
    ) {
      urlString += `${index === 0 ? "?" : ""}${key}=${params[key]}${
        index !== array.length - 1 ? "&" : ""
      }`;
    }
  });

  return urlString;
};
