import React, { useState, useEffect } from "react";
import ContainerBox from "../common/hoc/ContainerBox.hoc";
import Header from "../common/header/Header.common";
import UltraResultComponent from "./result/ultraResultComponent";

const UltraResultPage = () => {
  const [mount, setMount] = useState(true);
  useEffect(() => {
    return () => {
      setMount(false);
    };
  }, []);
  return (
    <ContainerBox>
      <Header title="Ultra Results" />
      <div className="result">{mount && <UltraResultComponent />}</div>
    </ContainerBox>
  );
};
export default UltraResultPage;
