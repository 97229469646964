import React, { useState, useEffect } from "react";

import { useForm } from "react-hook-form";
import { useNavigation } from "react-auth-navigation";

import { Button, FormControlLabel, Switch, Checkbox } from "@material-ui/core";

import moment from "moment";
import "react-day-picker/lib/style.css";

import { toast } from "react-toastify";

import { APIS } from "../../../config/Api.config";
import { api } from "../../../helpers/Api.helper";
import { useAuth } from "react-auth-navigation";
import { SelectField } from "../../common/selectField/selectfield.common";
import { Modal } from "react-uicomp";
import { UpdateCalliberationDate } from "./UpdateCalliberationDate";
import { SerialNumberData } from "./SerialNumberData";

const EditGxMachine = ({ editData }) => {
  const { userRole } = useAuth();
  const { navigation, params } = useNavigation();
  const { navigate, routes } = navigation;
  // const editData = props.location?.state
  const { register, handleSubmit, errors, control } = useForm({
    defaultValues: { site: editData?.site },
  });
  const [isActive, setIsActive] = useState(editData?.is_active);
  const [siteList, setSiteList] = useState([]);
  const [siteData, setSiteData] = useState(editData?.site);
  const [machineSerialList, setMachineSerialList] = useState(editData.serials);

  const makeModuleList = (serial) => {
    let modules = [];
    for (const data of serial) {
      for (const module of data.modules) {
        modules.push(module);
      }
    }
    return modules;
  };
  useEffect(() => {
    const fetch = async () => {
      let site_list_result = await api(APIS.site_list);
      setSiteList(site_list_result?.data);
    };

    fetch();
  }, []);

  const cancelHandler = () => {
    window.history.back();
  };

  const onSubmit = async (data) => {
    let body = {
      code: data.code,
      serial: data.serial ? data.serial : null,
      // warrantyStartDate: data?.warrantyStartmoment
      //   ? data.warrantyStart.format("YYYY-MM-DD")
      //   : null,
      // warrantyEndDate: data?.warrantyEndDate
      //   ? moment(data.warrantyEndDate).format("YYYY-MM-DD")
      //   : null,
      // calibrationDate: data?.caliberation
      //   ? moment(data.caliberation).format("YYYY-MM-DD")
      //   : null,
      site: siteData,
      modules: makeModuleList(machineSerialList),
      serials: machineSerialList,
      is_active: data.isActive,
    };
    let machine_post_result = await api(
      APIS.machine_response + `${params.id}/`,
      "PUT",
      body
    );

    if (machine_post_result.status === 200) {
      toast.success("GxMachine Updated", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      navigate({ pathname: routes["GX Machine"].path });
    }
  };

  const onChangeSwitchSerial = (index) => {
    let serialDetail = [...machineSerialList];
    let value = serialDetail[index].is_active;
    serialDetail[index].is_active = !value;

    if (value === true) {
      for (const i in serialDetail[index].modules) {
        serialDetail[index].modules[i].is_active = false;
      }
    }
    setMachineSerialList(serialDetail);
  };

  const onChangeSwitchModules = (moduleIndex, serialIndex) => {
    let serialDetail = [...machineSerialList];
    if (serialDetail[serialIndex].is_active === true) {
      let value = serialDetail[serialIndex].modules[moduleIndex].is_active;
      serialDetail[serialIndex].modules[moduleIndex].is_active = !value;
      setMachineSerialList(serialDetail);
    }
  };

  return (
    <>
      {/* <Header title="Edit Gx Machine" /> */}
      {/* {detail ? */}

      <div className="addgxmachine">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="addgxmachine-form">
            <div className="addgxmachine-form-site form">
              <label>GX-SITE</label>
              <SelectField
                options={siteList}
                getOptionLabel="name"
                getOptionValue="id"
                onChangeValue={(item) => {
                  setSiteData(item?.id);
                }}
                value={siteList?.find(
                  (item) => Number(item.id) === Number(siteData)
                )}
                placeholder="Select GxSite"
                isClearable={true}
                isSearchable={true}
              />
              {/* {siteList.length > 0 ? (
                <select
                  name="site"
                  ref={register({ required: true })}
                  defaultValue={editData?.site}
                  className={`${errors.site && "showerror"}`}
                >
                  {siteList.map((item, index) => {
                    return (
                      <option value={item.id} key={index}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <select className={`${errors.site && "showerror"}`}>
                  <option value="">Select Site</option>
                </select>
              )} */}
            </div>

            <div className="addgxmachine-form-row">
              <div className="addgxmachine-form-code form">
                <label>Code</label>
                <input
                  name="code"
                  defaultValue={editData ? editData.code : null}
                  ref={register({ required: true })}
                  className={`${errors.code && "showerror"} `}
                />
              </div>

              <div className="addgxmachine-form-serial form">
                <label>Serial Number</label>
                <input
                  name="serial"
                  defaultValue={editData ? editData.serial : null}
                  ref={register({ required: false })}
                  className={`${errors.serial && "showerror"} `}
                />
              </div>
            </div>

            {/* <div className="addgxmachine-form-row">
              <div className="addgxmachine-form-startdate date">
                <label>GX Warranty Start Date</label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    name="warrantyStart"
                    control={control}
                    defaultValue={
                      editData?.warrantyStartDate
                        ? new Date(editData.warrantyStartDate)
                        : null
                    }
                    rules={{ required: false }}
                    as={
                      <KeyboardDatePicker
                        label="Event date"
                        format="yyyy-MM-dd"
                        margin="normal"
                        // value={warrantyStart}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    }
                  />
                  {errors.warrantyStart && (
                    <div style={{ color: "red" }}>
                      <strong>Date is Required!!</strong>
                    </div>
                  )}
                </MuiPickersUtilsProvider>
              </div>
              <div className="addgxmachine-form-startdate date">
                <label>GX Warranty End Date</label>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    name="warrantyEndDate"
                    control={control}
                    defaultValue={
                      editData?.warrantyEndDate
                        ? new Date(editData.warrantyEndDate)
                        : null
                    }
                    rules={{ required: false }}
                    as={
                      <KeyboardDatePicker
                        label="Event date"
                        format="yyyy-MM-dd"
                        margin="normal"
                        // value={warrantyEnd}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    }
                  />
                  {errors.warrantyEndDate && (
                    <div style={{ color: "red" }}>
                      <strong>Date is Required!!</strong>
                    </div>
                  )}
                </MuiPickersUtilsProvider>
              </div>
            </div> */}

            <div className="addgxmachine-form-row">
              {/* <div className="addgxmachine-form-startdate date">
                <label>Calliberation Date</label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    name="caliberation"
                    control={control}
                    defaultValue={
                      editData?.calibrationDate
                        ? new Date(editData.calibrationDate)
                        : null
                    }
                    rules={{ required: false }}
                    as={
                      <KeyboardDatePicker
                        label="Event date"
                        format="yyyy-MM-dd"
                        margin="normal"
                        // value={caliberation}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    }
                  />
                  {errors.caliberation && (
                    <div style={{ color: "red" }}>
                      <strong>Date is Required!!</strong>
                    </div>
                  )}
                </MuiPickersUtilsProvider>
              </div> */}

              <FormControlLabel
                control={
                  <Checkbox
                    checked={isActive}
                    name="isActive"
                    onChange={() => setIsActive(!isActive)}
                    inputRef={register}
                  />
                }
                label="Machine is Active"
              />
            </div>
            {/* {moduleList.length > 0 &&
                            <div className="modules">
                                <div className="modules-title">Modules active status</div>
                                {moduleList.map((item, index) => {
                                    return (
                                        <div key={index} className="each-module">
                                            Module : {item.serial_no} {`  `}

                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={item?.is_active}
                                                        onChange={() => changeSwitch(index)}
                                                        color="primary"
                                                    />
                                                }
                                                label={`Active`}
                                            />

                                        </div>
                                    )
                                })}
                            </div>
                        } */}

            {/* {machineSerialList.length > 0 && userRole !== "1" &&

                            <div className="modules">
                                <div className="modules-title">Machine Serial active status</div>
                                {machineSerialList.filter(item => item.is_active_temp === true).map((item, index) => {

                                    return (
                                        <div key={index} className="each-module">
                                            Serial : {item.serial_no} {`  `}

                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={item?.is_active}
                                                        onChange={() => onChangeSwitchSerial(index)}
                                                        color="primary"
                                                    />
                                                }
                                                label={`Active`}
                                            />

                                        </div>
                                    )
                                })}
                            </div>
                        } */}

            {machineSerialList.length > 0 && (
              <div className="modules">
                <div className="modules-title">
                  Machine Serial active status
                </div>
                {machineSerialList.map((item, serialIndex) => {
                  return (
                    <SerialNumberData
                      key={item.id}
                      changeSwitchSerial={() => {
                        onChangeSwitchSerial(serialIndex);
                      }}
                      serialData={item}
                      changeSwitchModules={(data) =>
                        onChangeSwitchModules(data, serialIndex)
                      }
                    />
                    // <div key={serialIndex} className="each-module">
                    //   <div
                    //     style={{
                    //       display: "flex",
                    //       flexDirection: "row",
                    //       columnGap: 20,
                    //       alignItems: "center",
                    //     }}
                    //   >
                    //     <label>
                    //       Serial : {item.serial_no} {`  `}
                    //     </label>
                    //     <FormControlLabel
                    //       control={
                    //         <Switch
                    //           checked={item?.is_active}
                    //           onChange={() => changeSwitchSerial(serialIndex)}
                    //           color="primary"
                    //         />
                    //       }
                    //       label={`Active`}
                    //     />
                    //     <div>
                    //       <label>GX Warranty End Date : </label>
                    //       {item?.warranty_end_date
                    //         ? item.warranty_end_date
                    //         : "-"}{" "}
                    //     </div>
                    //     <div>
                    //       <label>Calliberation Date :</label>
                    //       {item?.calibration_date
                    //         ? item.calibration_date
                    //         : "-"}{" "}
                    //     </div>
                    //     {/* <div
                    //       onClick={() => {
                    //         setMachineSerialDetail(item);
                    //       }}
                    //     >
                    //       change date
                    //     </div> */}
                    //     <Button
                    //       type="button"
                    //       variant="contained"
                    //       color="primary"
                    //       onClick={() => {
                    //         setMachineSerialDetail(item);
                    //       }}
                    //     >
                    //       Update
                    //     </Button>
                    //   </div>
                    //   {item.modules.map((element, moduleIndex) => {
                    //     // console.log('module indec', moduleIndex)
                    //     return (
                    //       <div
                    //         key={moduleIndex}
                    //         className="each-module"
                    //         style={{ marginLeft: 50 }}
                    //       >
                    //         Module : {element.serial_no} {`  `}
                    //         <FormControlLabel
                    //           control={
                    //             <Switch
                    //               checked={element?.is_active}
                    //               onChange={() =>
                    //                 changeSwitchModules(
                    //                   moduleIndex,
                    //                   serialIndex
                    //                 )
                    //               }
                    //               color="primary"
                    //             />
                    //           }
                    //           label={`Active`}
                    //         />
                    //       </div>
                    //     );
                    //   })}
                    // </div>
                  );
                })}
              </div>
            )}

            {/* {machineSerialList.length > 0 && userRole !== "1" && (
              <div className="modules">
                <div className="modules-title">
                  Machine Serial active status
                </div>
                {machineSerialList.map((item, serialIndex) => {
                  return (
                    <div key={serialIndex} className="each-module">
                      Serial : {item.serial_no} {`  `}
                      <FormControlLabel
                        control={
                          <Switch
                            checked={item?.is_active}
                            onChange={() => changeSwitchSerial(serialIndex)}
                            color="primary"
                          />
                        }
                        label={`Active`}
                      />
                      {item.modules.map((element, moduleIndex) => {
                        // console.log('module indec', moduleIndex)
                        return (
                          <div
                            key={moduleIndex}
                            className="each-module"
                            style={{ marginLeft: 50 }}
                          >
                            Module : {element.serial_no} {`  `}
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={element?.is_active}
                                  onChange={() =>
                                    changeSwitchModules(
                                      moduleIndex,
                                      serialIndex
                                    )
                                  }
                                  color="primary"
                                />
                              }
                              label={`Active`}
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            )} */}

            {/* <Modal
              visible={!!machineSerialDetail}
              onClose={() => setMachineSerialDetail()}
              style={{
                width: "40vw",
                height: "50vh",
              }}
            >
              <UpdateCalliberationDate
                detail={machineSerialDetail}
                onClose={() => setMachineSerialDetail()}
              />
           
            </Modal> */}

            <div className="addgxmachine-form-row">
              <Button type="submit" variant="contained" color="primary">
                submit
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={cancelHandler}
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </div>

      {/* : <div>loading...</div>} */}
    </>
  );
};

export default EditGxMachine;
