import React, { useState, useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { api } from "../../../helpers/Helpers";
import { APIS } from "../../../config/Api.config";
import { Button, CircularProgress } from "@material-ui/core";
import moment from "moment";
export const WorkingModuleStatusPage = () => {
  const [moduleStatus, setModuleStatus] = useState([]);
  const [fromDate, setFromDate] = useState(() =>
    moment().subtract(6, "days").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(() => moment().format("YYYY-MM-DD"));
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);

  useEffect(() => {
    let mount = true;
    if (!!fromDate && !!toDate) {
      (async () => {
        setLoading(true);
        let res = await api(APIS.site_wise_working_modules_status, "POST", {
          start_date: fromDate,
          end_date: toDate,
        });
        if (mount && res.status === 200) {
          setModuleStatus(res.data);
          setLoading(false);
        }
        setLoading(false);
      })();
    }
    return () => {
      mount = false;
    };
  }, [fromDate, toDate]);

  const onDownloadCSV = async () => {
    const body = {
      fileFormat: "csv",
      fileDownload: true,
    };
    if (!!fromDate && !!toDate) {
      body["start_date"] = fromDate;
      body["end_date"] = toDate;
    }
    setDownloadLoading(true);

    let res = await api(APIS.site_wise_working_modules_status, "POST", body);
    console.log("site_wise_rate_analysis", res);
    setDownloadLoading(false);
  };
  console.log("moduleStatus", moduleStatus);

  const updateStartDay = (date) => {
    var result = new Date(date);
    result.setDate(result.getDate() + 6);

    setFromDate(date);
    setToDate(format(result, "yyyy-MM-dd"));
  };
  const updateEndDays = (date) => {
    var result = new Date(date);
    result.setDate(result.getDate() - 6);
    setFromDate(format(result, "yyyy-MM-dd"));
    setToDate(date);
  };

  return (
    // <ContainerBox>
    <div className="moduleStatus-container">
      <div className="moduleStatus">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="formWrapper">
            <label>Search by Date Range</label>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                columnGap: 20,
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  columnGap: 20,
                  width: 300,
                }}
              >
                <KeyboardDatePicker
                  disableToolbar
                  label="Date From"
                  format="yyyy-MM-dd"
                  margin="normal"
                  value={fromDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  onChange={(date, value) => {
                    updateStartDay(value);
                  }}
                  maxDate={(() => {
                    const dddd = new Date();
                    const red = dddd.setDate(dddd.getDate() - 6);
                    return red;
                  })()}
                />

                <KeyboardDatePicker
                  disableToolbar
                  label="Date To"
                  format="yyyy-MM-dd"
                  margin="normal"
                  value={toDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  onChange={(date, value) => {
                    console.log("date,value", { date, value });
                    updateEndDays(value);
                  }}
                  maxDate={new Date()}
                />
              </div>
              <div>
                <Button
                  type="button"
                  onClick={onDownloadCSV}
                  size="small"
                  style={{ marginLeft: 10 }}
                  disabled={loading === true}
                  variant="contained"
                  color="primary"
                  className="button"
                >
                  Export CSV
                </Button>
              </div>
            </div>
          </div>
        </MuiPickersUtilsProvider>

        {loading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th rowSpan={2}> Site</th>
                <th colSpan={2}>
                  {moduleStatus?.[0]?.module_working_data?.[0]?.date}
                </th>
                <th colSpan={2}>
                  {moduleStatus?.[1]?.module_working_data?.[1]?.date}
                </th>
                <th colSpan={2}>
                  {moduleStatus?.[2]?.module_working_data?.[2]?.date}
                </th>
                <th colSpan={2}>
                  {moduleStatus?.[3]?.module_working_data?.[3]?.date}
                </th>
                <th colSpan={2}>
                  {moduleStatus?.[4]?.module_working_data?.[4]?.date}
                </th>
                <th colSpan={2}>
                  {moduleStatus?.[5]?.module_working_data?.[5]?.date}
                </th>
                <th colSpan={2}>
                  {moduleStatus?.[6]?.module_working_data?.[6]?.date}
                </th>
              </tr>
              <tr>
                <th> Working</th>
                <th> Not Working</th>
                <th> Working</th>
                <th> Not Working</th>
                <th> Working</th>
                <th> Not Working</th>
                <th> Working</th>
                <th> Not Working</th>
                <th> Working</th>
                <th> Not Working</th>
                <th> Working</th>
                <th> Not Working</th>
                <th> Working</th>
                <th> Not Working</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <div>loading...</div>
              ) : (
                moduleStatus?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item?.site_name}</td>
                      {item.module_working_data?.map((modules, i) => {
                        return (
                          <>
                            <td key={`work-${i}`}>{modules.working_count}</td>
                            <td key={`notwork-${i}`}>
                              {modules.not_working_count}
                            </td>
                          </>
                        );
                      })}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
    // </ContainerBox>
  );
};
