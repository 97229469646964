import React, { useState, useEffect } from "react";
import ContainerBox from "../../common/hoc/ContainerBox.hoc";
import Header from "../../common/header/Header.common";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { api } from "../../../helpers/Api.helper";
import { APIS } from "../../../config/Api.config";
import { useNavigation } from "react-auth-navigation";
import { ActivityIndicator } from "../../common/hoc";

const DelayOneTwo = () => {
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  console.log(navigate, routes);
  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });
  const [delayMachine, setDelayMachine] = useState();
  const [delayMachineLoading, setDelayMachineLoading] = useState(true);
  // const delay = async () => {
  //   let machine_status_response = await api(APIS.machine_status_result);
  //   console.log(machine_status_response.data.delay_1_2_days);
  //   setDelayMachine(machine_status_response?.data.delay_1_2_days);
  // };

  useEffect(() => {
    // delay();
    let mount = true;
    (async () => {
      setDelayMachineLoading(true);
      let machine_status_response = await api(APIS.machine_status_result);
      console.log(machine_status_response.data.delay_1_2_days);
      if (mount && machine_status_response?.status === 200) {
        setDelayMachine(machine_status_response?.data.delay_1_2_days);
        setDelayMachineLoading(false);
      }
      setDelayMachineLoading(false);
    })();
    return () => {
      mount = false;
    };
  }, []);

  const classes = useStyles();

  return (
    <ContainerBox>
      <Header title="Machine Delay 1-2 Days" />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(500px, 1fr))",
          columnGap: 20,
          rowGap: 20,
        }}
      >
        <ActivityIndicator animating={delayMachineLoading}>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              size="medium"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>S.N</TableCell>
                  <TableCell align="left">GX Site</TableCell>
                  <TableCell align="left">Machine Code</TableCell>
                  <TableCell align="center">Machine Serial Number</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {delayMachine?.length > 0 ? (
                  delayMachine.map((item, index) => (
                    <TableRow
                      key={index}
                      hover
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(routes["GX Sites"].path + "/" + item.site)
                      }
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="left">{item.site_name}</TableCell>
                      <TableCell align="left">{item.code}</TableCell>
                      <TableCell align="center">{item.serial}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      rowSpan={4}
                      colSpan={4}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        No data Available
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </ActivityIndicator>
      </div>
    </ContainerBox>
  );
};

export default DelayOneTwo;
