import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useAuth, useNavigation } from "react-auth-navigation";
import { Button } from "@material-ui/core";

import { useNotification } from "../../../context";

import { api } from "../../../helpers/Helpers";
import { APIS } from "../../../config/Api.config";
import { useInput } from "../../../hooks";
import { isValid, validator } from "../../../util";

import ContainerBox from "../../common/hoc/ContainerBox.hoc";
import { ActivityIndicator } from "../../common/hoc";
import Header from "../../common/header/Header.common";
import { SelectField } from "../../common/selectField/selectfield.common";

export const AddTicketPage = () => {
  const { navigation } = useNavigation();
  const { userRole } = useAuth();
  console.log("useNavigation()", useNavigation());
  const { onNotificationHandler } = useNotification();
  const { data, onChangeHandler, setError } = useInput({
    description: "",
    siteId: "",
    machineId: "",
    assignTo: "",
  });

  const [siteList, setSiteList] = useState([]);
  const [ticketLoader, setTicketLoader] = useState(false);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    let mount = true;
    const fetch = async () => {
      let site_list_result = await api(APIS.site_list);
      if (mount && site_list_result.status === 200) {
        setSiteList(site_list_result?.data);
      }
    };
    fetch();
    return () => {
      mount = false;
    };
  }, []);

  useEffect(() => {
    let mount = true;
    const onGetUserHandler = async (id) => {
      let users = [];
      let res = await api(APIS.getSiteUsers, "POST", { site: id });

      if (res?.data[3]?.length > 0) {
        users = res?.data[3];
      }

      if (mount) {
        setUserList(users.filter(Boolean));
      }
    };

    if (data.siteId) {
      onGetUserHandler(data.siteId);
    }
    return () => {
      mount = false;
    };
  }, [data.siteId]);

  const onSubmit = async (e) => {
    e.preventDefault();

    const { description, siteId, machineId, assignTo } = data;
    const catchedErrors = {};
    const validate = validator(catchedErrors);

    validate("siteId", siteId.length === 0, () => {
      toast.error("Site Id is required!");
    });

    validate("machineId", machineId.length === 0, () => {
      toast.error("Machine Id is required!");
    });

    validate("description", description.length === 0, () => {
      toast.error("Description is required!");
    });

    // validate("assignTo", assignTo.length === 0, () => {
    //   toast.error("Assign To is required!");
    // });

    if (!isValid(catchedErrors)) {
      setError(catchedErrors);
      return;
    }

    setTicketLoader(true);
    let add_ticket_result = await api(APIS.ticket, "POST", {
      description: description,
      site: siteId,
      machine: machineId,
    });

    if (add_ticket_result.status === 201) {
      onNotificationHandler();
      toast.success("Ticket Added!");

      if (!!data?.assignTo) {
        let addAssignedTo = await api(
          `${APIS.ticket}${add_ticket_result?.data?.id}/`,
          "PATCH",
          {
            assigned_to: data?.assignTo,
          }
        );

        if (addAssignedTo.status === 200) {
          setTicketLoader(false);
          onNotificationHandler();
          toast.success("Ticket Assigned!");
          navigation.navigate("/ticket");
        }
        return;
      }

      navigation.navigate("/ticket");
    }
    setTicketLoader(false);
  };

  return (
    <ContainerBox>
      <Header title="Add Ticket" />
      <div className="addticket">
        <form onSubmit={onSubmit}>
          <div className="addticket-form">
            <div className="editmachinestatus-form-site form">
              <label>GX-SITE</label>
              <SelectField
                options={siteList}
                getOptionLabel="name"
                getOptionValue="id"
                onChangeValue={(item) => {
                  // setSiteData(item?.id);
                  onChangeHandler("siteId", item?.id)();
                }}
                value={siteList?.find((item) => item.id === data.siteId)}
                placeholder="Select GxSite"
                isClearable={true}
                isSearchable={true}
              />
            </div>

            <div className="editmachinestatus-form-site form">
              <label>GXMachine</label>
              <SelectField
                options={
                  siteList?.find(
                    (result) => result.id === parseInt(data.siteId)
                  )?.machines
                }
                getOptionLabel="code"
                getOptionValue="id"
                onChangeValue={(item) => {
                  // setMachineId(item?.id);
                  onChangeHandler("machineId", item?.id)();
                }}
                value={siteList
                  ?.find((result) => result.id === parseInt(data.siteId))
                  ?.machines?.find((item) => item.id === data.machineId)}
                placeholder="Select GxSite"
                isClearable={true}
                isSearchable={true}
              />
            </div>

            {userRole !== "6" && (
              <div className="editmachinestatus-form-site form">
                <label> Assign To</label>

                <SelectField
                  options={userList}
                  getOptionLabel="username"
                  getOptionValue="id"
                  onChangeValue={(item) => {
                    onChangeHandler("assignTo", item?.id)();
                  }}
                  placeholder="Select GxSite"
                  isClearable={true}
                  isSearchable={true}
                />
              </div>
            )}

            <div className="addgxsite-form-name form">
              <label>Description</label>
              <textarea
                rows="5"
                name="description"
                onChange={onChangeHandler("description")}
                // className={`${errors.name && "showerror"} `}
                required
              />
            </div>

            <div className="addgxsite-form-row">
              <ActivityIndicator animating={ticketLoader}>
                <Button type="submit" variant="contained" color="primary">
                  submit
                </Button>
              </ActivityIndicator>
            </div>
          </div>
        </form>
      </div>
    </ContainerBox>
  );
};
