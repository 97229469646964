import React, { useState } from "react";
import { toast } from "react-toastify";

import UsernameLogo from "../../assets/icons/Username.png";

import { useNavigation } from "react-auth-navigation";
import { useForm } from "react-hook-form";
import { APIS } from "../../config/Api.config";
import { api } from "../../helpers/Api.helper";
import { InvertedButton } from "../common/button/Button.common";

const ForgetPasswordPage = () => {
  const { navigation } = useNavigation();
  const { routes, navigate } = navigation;
  const { handleSubmit, register, setValue } = useForm();
  const [newPassword, setNewPassword] = useState();

  const onSubmit = async (data) => {
    let body = {
      username: data.username,
    };
    let res = await api(APIS.reset_password, "POST", body, {
      Accept: "application/json",
      "Content-Type": "application/json",
    });
    if (res?.status === 200) {
      setValue("username", "");
      setNewPassword(res?.data?.password);
      toast.success("Reset successful", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    } else {
      toast.error(res?.data?.error, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    }
  };
  return (
    <div className="login-container">
      <div className="login">
        <div className="login-title">RESET PASSWORD</div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="login-input">
            <div className="login-input-logo">
              <img src={UsernameLogo} alt="USERNAME" />
            </div>

            <input
              name="username"
              ref={register({ required: true })}
              type="text"
              className="login-input-field"
              placeholder="Username"
            />
          </div>

          <div className="login-submit">
            <InvertedButton
              title="Reset Password"
              style={{ width: "100%", paddingTop: 10, paddingBottom: 10 }}
              onClick={() => false}
            />
          </div>
        </form>
        <div
          className="login-click"
          onClick={() => navigate(routes["Login"].path)}
        >
          Back to Login
        </div>
      </div>
      {newPassword && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div>
            Your new password is <i>&nbsp; {newPassword}.&nbsp;</i>
          </div>
          <div>Please change your password after logging in.</div>
        </div>
      )}
    </div>
  );
};

export default ForgetPasswordPage;
