import React, { useState, useEffect, useCallback } from "react";
import MaterialTable from "material-table";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { api } from "../../../helpers/Api.helper";
import { APIS } from "../../../config/Api.config";
import Pagination from "@material-ui/lab/Pagination";
import { useNavigation } from "react-auth-navigation";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useComponentDidUpdate, useDebounceValue } from "../../../hooks";
import { SelectField } from "../../common/selectField/selectfield.common";

const ATTRIBUTE = [
  { name: "POSITIVE", value: "POSITIVE" },
  { name: "NEGATIVE", value: "NEGATIVE" },
];
const CovidResult = () => {
  const { location, history } = useNavigation();

  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const [pageNumber, setPageNumber] = useState(query.get("page") || 1);

  const [fromDate, setFromDate] = useState(query.get("fromDate") || null);
  const [toDate, setToDate] = useState(query.get("toDate") || null);
  const [siteValue, setSiteValue] = useState(query.get("site") || "");
  const [machineId, setMachineId] = useState(query.get("machinecode") || "");
  const [instrument, setInstrument] = useState(query.get("instrument") || "");
  const [sampleId, setSampleId] = useState(query.get("sample") || "");
  const [covidResult, setCovidResult] = useState(
    query.get("covidResultType") || ""
  );
  const instrumentValue = useDebounceValue(instrument);
  const sampleIdValue = useDebounceValue(sampleId);

  const [tableData, setTableData] = useState();
  const [tableDataArray, setTableDataArray] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const [searchLoader, setSearchLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingReport, setIsLoadingReport] = useState(true);

  // const fetchReport = async () => {
  //   setIsLoadingReport(true);
  //   const response = await api(APIS.get_covid_report_data, "POST", {
  //     end_date: "2020-02-01",
  //     start_date: moment().format("YYYY-MM-DD"),
  //   });
  //   if (response?.status === 200) {
  //     setTableData(response?.data);
  //   }
  // };

  // console.log("Site List", siteList)
  const fetchResult = async (newPage = 1) => {
    history.replace(`${location.pathname}?page=${newPage}`);
    setIsLoading(true);
    let machine_result = await api(APIS.covid_result + `?page=${newPage}`);
    if (machine_result?.status === 200) {
      setTableDataArray(machine_result?.data);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchSiteList = async () => {
      let site_list_result = await api(APIS.site_list).catch((error) => {
        console.log(site_list_result.error);
      });
      console.log("site_list_result", site_list_result);
      if (site_list_result?.status === 200) {
        setSiteList(site_list_result?.data);
      }
    };
    fetchSiteList();
  }, []);

  // useEffect(() => {
  //   searchReport(siteValue);
  //   // fetchReport();
  //   // fetchSiteList();
  // }, [fromDate, toDate, siteValue]);

  // useEffect(() => {
  //   fetchReport({});
  // }, []);

  useEffect(() => {
    const body = {};
    // if ((!!fromDate && !!toDate) || !!siteValue) {
    if (fromDate && toDate) {
      body.start_date = fromDate;
      body.end_date = toDate;
    }
    if (!!siteValue) {
      body.site_id = siteValue;
    }
    if (!!machineId) {
      body.machine_code = machineId;
    }
    if (!!instrumentValue) {
      body.instrument_serial_number = instrumentValue;
    }
    if (!!sampleIdValue) {
      body.sample_id = sampleIdValue;
    }
    if (!!covidResult) {
      body.covid_result = covidResult;
    }

    fetchReport(body);
    // }
  }, [
    fromDate,
    toDate,
    siteValue,
    machineId,
    instrumentValue,
    sampleIdValue,
    covidResult,
  ]);

  useEffect(() => {
    (fromDate && toDate) ||
    siteValue ||
    machineId ||
    sampleIdValue ||
    instrumentValue ||
    covidResult
      ? searchResult(
          fromDate,
          toDate,
          siteValue,
          machineId,
          sampleIdValue,
          instrumentValue,
          covidResult,
          pageNumber
        )
      : fetchResult(pageNumber);
  }, [pageNumber]);

  useComponentDidUpdate(() => {
    if (pageNumber === 1) {
      (fromDate && toDate) ||
      siteValue ||
      machineId ||
      sampleIdValue ||
      instrumentValue ||
      covidResult
        ? searchResult(
            fromDate,
            toDate,
            siteValue,
            machineId,
            sampleIdValue,
            instrumentValue,
            covidResult,
            1
          )
        : fetchResult(1);
    } else {
      setPageNumber(1);
    }
  }, [
    fromDate,
    toDate,
    siteValue,
    machineId,
    instrumentValue,
    sampleIdValue,
    covidResult,
  ]);

  // useComponentDidUpdate(() => {
  //   searchReport(siteValue);
  // }, [fromDate, toDate, siteValue]);

  const page = async (event, newPage = 1) => {
    setPageNumber(newPage);
  };

  const fetchReport = useCallback(async (body) => {
    setIsLoadingReport(true);
    const response = await api(APIS.get_covid_report_data, "POST", body);
    if (response.status === 200) {
      setTableData(response?.data);
      setIsLoadingReport(false);
    }
    // setIsLoadingReport(false);
  }, []);

  // const searchReport = async (siteId) => {
  //   setIsLoadingReport(true);
  //   siteId = siteId != null ? Number(siteId) : siteId;
  //   const body = {
  //     end_date: moment().format("YYYY-MM-DD"),
  //     start_date: "2020-02-01",
  //   };
  //   if (fromDate) {
  //     body.start_date = fromDate;
  //   }
  //   if (toDate) {
  //     body.end_date = toDate;
  //   }
  //   if (siteId) {
  //     body.site_id = siteId;
  //   }
  //   const response = await api(APIS.get_covid_report_data, "POST", body);
  //   if (response?.status === 200) {
  //     setTableData(response?.data);
  //     setIsLoadingReport(false);
  //   }
  //   setIsLoadingReport(false);
  // };

  const searchResult = async (
    bodyFromDate,
    bodyToDate,
    bodySiteId,
    bodyMachineId,
    bodySampleId,
    bodyInstrument,
    bodyCovidResult,
    newPage = 1
  ) => {
    setIsLoading(true);
    setSearchLoader(true);
    let dateRangeFrom = null;
    let dateRangeTo = null;
    let filter = "";
    if (bodyFromDate && bodyToDate) {
      dateRangeFrom = bodyFromDate;
      dateRangeTo = bodyToDate;

      filter += `&fromDate=${fromDate}&toDate=${toDate}`;
    }

    if (!!siteValue) {
      filter += `&site=${siteValue}`;
    }
    if (!!machineId) {
      filter += `&machinecode=${machineId}`;
    }
    if (!!sampleIdValue) {
      filter += `&sample=${sampleIdValue}`;
    }
    if (!!instrumentValue) {
      filter += `&instrument=${instrumentValue}`;
    }
    if (!!covidResult) {
      filter += `&covidResultType=${covidResult}`;
    }
    history.replace(`${location.pathname}?page=1${filter}`);

    let machine_result = await api(
      APIS.covid_result + `search/?page=${newPage}`,
      "POST",
      {
        end_date: dateRangeTo,
        start_date: dateRangeFrom,
        site_id: bodySiteId,
        machine_code: bodyMachineId,
        sample_id: bodySampleId,
        instrument_serial_number: bodyInstrument,
        covid_result: bodyCovidResult,
      }
    );
    if (machine_result.status === 200) {
      // window.scrollTo(0, 0);
      setTableDataArray(machine_result?.data);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  function formatDate(date) {
    if (!date) return null;
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const onDownload = async () => {
    const response = await api("api/resultscovid/download_csv/", "POST", {
      start_date: fromDate == null ? null : fromDate,
      end_date: toDate == null ? null : toDate,
      site_id: siteValue,
      machine_code: machineId,
      sample_id: sampleIdValue,
      instrument_serial_number: instrumentValue,
      mtb_result: covidResult,
    });
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "covid.csv";
    link.href = URL.createObjectURL(
      new Blob([response.data], { type: "text/csv" })
    );
    link.click();
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      "& > * + *": {
        marginLeft: theme.spacing(2),
      },
    },
  }));

  const classes = useStyles();

  console.log("tableData", tableData);
  return (
    <div className="resultpage">
      <form>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="utilsContainer">
            <div className="formWrapper" style={{ height: "100%" }}>
              <label>Search by Date Range</label>
              <div className="spacing" style={{ height: "100%" }}>
                <KeyboardDatePicker
                  disableToolbar
                  label="Date From"
                  format="yyyy-MM-dd"
                  margin="normal"
                  value={fromDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  onChange={(date, value) => {
                    setFromDate(value);
                  }}
                />

                <KeyboardDatePicker
                  disableToolbar
                  label="Date To"
                  format="yyyy-MM-dd"
                  margin="normal"
                  value={toDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  onChange={(date, value) => {
                    setToDate(value);
                  }}
                />
              </div>
            </div>

            <div className="formWrapper spacing">
              <div className=" form">
                <label>Search by Site</label>
                <SelectField
                  options={siteList}
                  getOptionLabel="name"
                  getOptionValue="id"
                  onChangeValue={(item) => {
                    console.log("siteData", item);
                    setSiteValue(item?.id);
                  }}
                  value={siteList?.find(
                    (item) => Number(item.id) === Number(siteValue)
                  )}
                  placeholder="Search By Site"
                  isClearable={true}
                  isSearchable={true}
                />

                {/* <div style={{ display: "flex", flexDirection: "row" }}>
                  <select
                    value={siteValue}
                    onChange={(e) => setSiteValue(e.target.value)}
                  >
                    <option value="">------</option>
                    {siteList?.length > 0 &&
                      siteList.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                </div> */}
              </div>

              <div className=" form">
                <label>GXMachine</label>
                <SelectField
                  options={
                    siteList?.find(
                      (result) => result.id === parseInt(siteValue)
                    )?.machines
                  }
                  getOptionLabel="code"
                  getOptionValue="id"
                  onChangeValue={(item) => {
                    console.log("siteData", item);
                    setMachineId(item?.id);
                  }}
                  value={siteList
                    ?.find((result) => result.id === parseInt(siteValue))
                    ?.machines?.find(
                      (item) => Number(item.id) === Number(machineId)
                    )}
                  placeholder="Search By Machine"
                  isClearable={true}
                  isSearchable={true}
                />

                {/* {siteList.length > 0 ? (
                  <select
                    value={machineId}
                    onChange={(e) => setMachineId(e.target.value)}
                  >
                    <option value="">Select Machine</option>
                    {siteValue &&
                      siteList
                        .find((result) => result.id === parseInt(siteValue))
                        ?.machines.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {item.code}
                            </option>
                          );
                        })}
                  </select>
                ) : (
                  <select>
                    <option value="">Select Machine</option>
                  </select>
                )} */}
              </div>
            </div>

            <div className="formWrapper spacing">
              <div className=" form">
                <label>Instrument Serial number</label>
                <input
                  style={{ height: 40 }}
                  value={instrument}
                  onChange={(e) => setInstrument(e.target.value)}
                />
              </div>

              <div className=" form">
                <label>Sample id</label>
                <input
                  style={{ height: 40 }}
                  value={sampleId}
                  onChange={(e) => setSampleId(e.target.value)}
                />
              </div>
            </div>

            <div className="formWrapper spacing">
              <div className="editmachinestatus-form-site form">
                <label>Search</label>

                <SelectField
                  options={ATTRIBUTE}
                  getOptionLabel="name"
                  getOptionValue="value"
                  onChangeValue={(item) => {
                    console.log("siteData", item);
                    setCovidResult(item?.value);
                  }}
                  value={ATTRIBUTE?.find((item) => item.value === covidResult)}
                  placeholder="Search By Attribute"
                  isClearable={true}
                  isSearchable={true}
                />

                {/* <select
                  // name="covid_result"
                  // ref={register}
                  // defaultValue={location.state}

                  value={covidResult}
                  onChange={(e) => setCovidResult(e.target.value)}
                >
                  <option value="">Select Attribute</option>
                  <option value="POSITIVE">POSITIVE</option>
                  <option value="NEGATIVE">NEGATIVE</option>
                </select> */}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  height: 80,
                }}
              >
                {/* <ActivityIndicator animating={searchLoader}>
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    color="primary"
                    className="button"
                    style={{ backgroundColor: "green", color: "white" }}
                  >
                    Search
                  </Button>
                </ActivityIndicator> */}
                <Button
                  type="submit"
                  onClick={onDownload}
                  size="small"
                  style={{ marginLeft: 10 }}
                  disabled={
                    searchLoader === true ||
                    typeof tableDataArray.results === "undefined"
                  }
                  variant="contained"
                  color="primary"
                  className="button"
                >
                  Download
                </Button>
              </div>
            </div>
          </div>
        </MuiPickersUtilsProvider>
      </form>

      {!tableData && isLoadingReport && (
        <div className={classes.root}>
          <CircularProgress />
        </div>
      )}
      <TableContainer component={Paper} style={{ marginBottom: 20 }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>TOTAL COVID TEST</TableCell>
              <TableCell>COVID POSITIVE </TableCell>
              <TableCell>COVID NEGATIVE </TableCell>
              <TableCell>COVID INVALID </TableCell>
              <TableCell>COVID NULL </TableCell>
            </TableRow>
          </TableHead>
          {/* {tableData ? ( */}
          <TableBody>
            {isLoadingReport ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress />
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell>{tableData?.COVID_TOTAL ?? 0}</TableCell>
                <TableCell>{tableData?.COVID_POSITIVE ?? 0}</TableCell>
                <TableCell>{tableData?.COVID_NEGATIVE ?? 0}</TableCell>
                {/* <TableCell>{tableData?.COVID_INDETERMINATE ?? 0}</TableCell> */}
                <TableCell>{tableData?.COVID_INVALID ?? 0}</TableCell>
                <TableCell>{tableData?.COVID_NULL ?? 0}</TableCell>
                {/* <TableCell>{tableData?.COVID_PRESUMPTIVE_POS ?? 0}</TableCell> */}
              </TableRow>
            )}
          </TableBody>
          {/* ) : null} */}
        </Table>
      </TableContainer>
      {/* {!tableDataArray && isLoading && (
        <div className={classes.root}>
          {" "}
          <CircularProgress />
        </div>
      )} */}
      <MaterialTable
        columns={[
          { title: "Site", field: "machine.site_name" },
          { title: "Machine ID", field: "machine.code" },
          {
            title: "Instrument Serial Number",
            field: "instrument_serial_number",
          },
          { title: "Module Serial Number", field: "module" },
          { title: "Reagent Lot Id", field: "reagent_lot_id" },
          { title: "Covid Result", field: "covid_result" },

          { title: "Sample ID", field: "sample_id" },
          {
            title: "Date",
            field: "date",
            render: (rowData) => <div>{formatDate(rowData.date)}</div>,
          },
          {
            title: "Error",
            field: "error",
            render: (rowData) => (
              <div className="tooltip">
                {rowData.error.slice(0, 10)}{" "}
                <span className="tooltiptext">{rowData.error}</span>
              </div>
            ),
          },

          {
            title: "Error Desc.",
            field: "error",
            render: (rowData) => (
              <div className="tooltip" style={{ width: 150 }}>
                {rowData.error.slice(11)}{" "}
                {/* <span className="tooltiptext">{rowData.error}</span> */}
              </div>
            ),
          },

          { title: "Version", field: "software_version" },
        ]}
        data={tableDataArray?.results}
        isLoading={isLoading}
        options={{
          toolbar: false,
          filtering: true,
          actionsColumnIndex: -1,
          emptyRowsWhenPaging: false,
          search: false,
          pageSizeOptions: [5, 10],
          pageSize: 10,
          paging: false,
        }}
      />

      <div style={{ width: "100%", display: "flex" }}>
        <Pagination
          style={{ marginLeft: "auto", marginTop: 20, display: "inline-block" }}
          count={Math.ceil(tableDataArray?.count / 100)}
          boundaryCount={1}
          page={Number(pageNumber)}
          variant="outlined"
          shape="rounded"
          onChange={page}
        />
      </div>
    </div>
  );
};

export default CovidResult;
