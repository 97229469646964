import React, { useState, useEffect, useCallback } from "react";
import ContainerBox from "../common/hoc/ContainerBox.hoc";
import { Button, CircularProgress } from "@material-ui/core";
// import { useForm } from "react-hook-form";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { toast } from "react-toastify";

import { api } from "../../helpers/Helpers";
import { APIS } from "../../config/Config";
import { useNavigation } from "react-auth-navigation";
import { Modal } from "react-uicomp";
import Header from "../common/header/Header.common";
import { getParsedUrl } from "../../helpers/parseUrl.helper";
import { useComponentDidUpdate, useDebounceValue } from "../../hooks";
import { SelectField } from "../common/selectField/selectfield.common";
import { useForm } from "react-hook-form";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { CgClose } from "react-icons/cg";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const USER_TYPE = [
  {
    label: "Admin",
    value: "1",
  },
  {
    label: "Central",
    value: "2",
  },
  {
    label: "Engineer",
    value: "3",
  },
  {
    label: "Provincial",
    value: "4",
  },
  {
    label: "District",
    value: "5",
  },
  {
    label: "GX Sites",
    value: "6",
  },
];
const UsersPage = () => {
  const classes = useStyles();
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(false);
  const [resetPasswordvisible, setResetPasswordvisible] = useState(false);
  const [activeUser, setActiveUser] = useState(null);
  const [resetLoader, setResetLoader] = useState(false);
  const [userType, setUserType] = useState("");
  const [username, setUsername] = useState("");
  const userNameDebounce = useDebounceValue(username);
  const [newPasswordModal, setNewPasswordModal] = useState(null);
  const [siteList, setSiteList] = useState([]);
  const [siteValue, setSiteValue] = useState();

  const [provinceList, setProvinceList] = useState([]);
  const [province, setProvince] = useState();
  const [districtList, setDistrictList] = useState([]);
  const [district, setDistrict] = useState();

  const siteRef = React.useRef();
  const provinceRef = React.useRef();
  const districtRef = React.useRef();

  useEffect(() => {
    console.log(">>>");
    fetchUser(1, siteValue, province, district);
  }, [userNameDebounce, userType]);

  const onClearSiteData = () => {
    siteRef.current.select.clearValue();
    setProvince(null);
    setDistrict(null);
  };
  const onClearProvinceData = () => {
    provinceRef.current.select.clearValue();
    setSiteValue(null);
    setDistrict(null);
  };

  const onClearDistrictData = () => {
    districtRef.current.select.clearValue();
    setSiteValue(null);
    setProvince(null);
  };

  useComponentDidUpdate(() => {
    if (siteValue) {
      fetchUser(1, siteValue, null, null);
      onClearProvinceData();
      onClearDistrictData();
    }
  }, [siteValue]);

  useComponentDidUpdate(() => {
    if (province) {
      fetchUser(1, null, province, null);
      onClearSiteData();
      onClearDistrictData();
    }
  }, [province]);

  useComponentDidUpdate(() => {
    if (district) {
      fetchUser(1, null, null, district);
      onClearSiteData();
      // onClearProvinceData();
    }
  }, [district]);

  useEffect(() => {
    (async () => {
      let site_list_result = await api(APIS.site_list).catch((error) => {
        console.log(site_list_result.error);
      });
      setSiteList(site_list_result?.data);
    })();
    // fetchSiteList();
  }, []);

  useEffect(() => {
    fetchProvince();
  }, []);

  useComponentDidUpdate(() => {
    province && fetchDistrict(province);
  }, [province]);

  async function fetchProvince() {
    const res = await api(APIS.get_province);
    setProvinceList(res?.data?.results);
  }

  async function fetchDistrict(provinceId) {
    const res = await api(APIS.get_district + provinceId + "/");
    setDistrictList(res?.data);
  }

  const userDetailHandler = (data) => {
    navigate({
      pathname: routes["Users"].path + "/" + data.id,
    });
  };
  const editUser = (data) => {
    navigate({
      pathname: routes["Users"].path + "/edit/" + data.id,
    });
  };

  const fetchUser = useCallback(
    async (newPage = 1, siteValue, province, district) => {
      setLoading(true);
      const params = {
        page: newPage,
      };
      if (!!userNameDebounce) {
        params["username"] = userNameDebounce;
      }
      if (!!userType) {
        params["user_type"] = userType;
      }
      if (!!siteValue) {
        params["hi"] = siteValue;
      }
      if (!!province) {
        params["province"] = province;
      }
      if (!!district) {
        params["district"] = district;
      }

      const res = await api(APIS.users + getParsedUrl(params));
      setUserData(res?.data);
      setLoading(false);
    },
    [userNameDebounce, userType]
  );

  const onPageChange = useCallback((event, newPage = 1) => {
    fetchUser(newPage, siteValue, province, district);
  }, []);

  const onResetPassword = (data) => {
    setActiveUser(data);
    setResetPasswordvisible(true);
  };

  const onCloseHandler = () => {
    setNewPasswordModal(null);
    setActiveUser(null);
  };

  return (
    <ContainerBox>
      <Header title="Users" />

      <Modal visible={!!newPasswordModal} onClose={onCloseHandler}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          New password is: {newPasswordModal}
          <div
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="default"
              style={{ background: "red", color: "white" }}
              onClick={onCloseHandler}
            >
              {" "}
              Close{" "}
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        visible={resetPasswordvisible}
        onClose={() => setResetPasswordvisible(false)}
      >
        <ResetPassword
          userName={activeUser?.username}
          onCloseHandler={() => {
            setResetPasswordvisible(false);
            setActiveUser(null);
          }}
          setNewPasswordModal={setNewPasswordModal}
        />
      </Modal>
      <div className="userpage">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <Button
              onClick={() => navigate(routes["Add Users"].path)}
              variant="contained"
              color="primary"
            >
              Add Users
            </Button>
          </div>

          <div className="form">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  marginRight: 10,
                  rowGap: 10,
                  columnGap: 10,
                }}
              >
                <SelectField
                  ref={siteRef}
                  options={siteList}
                  getOptionLabel="name"
                  getOptionValue="id"
                  onChangeValue={(item) => {
                    console.log("siteData", item);
                    setSiteValue(item?.id);
                    // onClearProvinceData();
                    // onClearDistrictData();
                  }}
                  value={siteList?.find(
                    (item) => Number(item.id) === Number(siteValue)
                  )}
                  placeholder="Search By Site"
                  isClearable={true}
                  isSearchable={true}
                />
                <SelectField
                  options={USER_TYPE}
                  getOptionLabel="label"
                  getOptionValue="value"
                  onChangeValue={(item) => {
                    console.log("siteData", item);
                    setUserType(item?.value);
                  }}
                  value={USER_TYPE?.find((item) => item.value === userType)}
                  placeholder="Search By User Type"
                  isClearable={true}
                  isSearchable={true}
                />

                <input
                  name="searchvalue"
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Search Username"
                />

                <SelectField
                  ref={provinceRef}
                  options={provinceList}
                  getOptionLabel="name"
                  getOptionValue="id"
                  onChangeValue={(item) => {
                    setProvince(item?.id);
                    // onClearSiteData();
                    // onClearDistrictData();
                  }}
                  value={provinceList?.find(
                    (item) => Number(item.id) === Number(province)
                  )}
                  placeholder="Search By Province"
                  isClearable={true}
                  isSearchable={true}
                />
                <SelectField
                  ref={districtRef}
                  options={districtList}
                  getOptionLabel="name"
                  getOptionValue="id"
                  onChangeValue={(item) => {
                    setDistrict(item?.id);
                    // onClearSiteData();
                    // onClearProvinceData();
                  }}
                  value={districtList?.find(
                    (item) => Number(item.id) === Number(district)
                  )}
                  placeholder="Search By district"
                  isClearable={true}
                  isSearchable={true}
                />
              </div>
            </div>
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>S.N</TableCell>
                <TableCell align="right">Username</TableCell>
                <TableCell align="right">First Name</TableCell>
                <TableCell align="right">Last Name</TableCell>
                <TableCell align="right">Email</TableCell>
                <TableCell align="right">User Type</TableCell>
                <TableCell align="right">Province</TableCell>
                <TableCell align="right">District</TableCell>
                <TableCell align="right">Municipality</TableCell>
                <TableCell align="right">Ward</TableCell>
                <TableCell align="right">Health Facility</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  height: 200,
                  width: "100%",
                }}
              >
                <div>Loading...</div>
              </div>
            ) : (
              <TableBody>
                {userData?.results.length > 0 &&
                  userData?.results.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {item.id}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ cursor: "pointer" }}
                        onClick={() => userDetailHandler(item)}
                        key={index}
                      >
                        {item.username}
                      </TableCell>
                      <TableCell align="right">{item.first_name}</TableCell>
                      <TableCell align="right">{item.last_name}</TableCell>
                      <TableCell align="right">{item.email}</TableCell>
                      <TableCell align="right">{item.user_type}</TableCell>
                      <TableCell align="right">{item.province}</TableCell>
                      <TableCell align="right">{item.district}</TableCell>
                      <TableCell align="right">{item.municipality}</TableCell>
                      <TableCell align="right">{item.wards}</TableCell>
                      <TableCell align="right">{item.hi}</TableCell>
                      <TableCell align="right">
                        <Button
                          variant="contained"
                          onClick={() => {
                            editUser(item);
                          }}
                          color="primary"
                        >
                          Edit
                        </Button>
                        <Button
                          onClick={() => {
                            onResetPassword(item);
                            // setResetPasswordvisible(true);
                          }}
                          color="secondary"
                        >
                          Reset Password
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <div style={{ width: "100%", display: "flex" }}>
          <Pagination
            style={{
              marginLeft: "auto",
              marginTop: 20,
              display: "inline-block",
            }}
            count={Math.ceil(userData?.count / 10)}
            boundaryCount={1}
            variant="outlined"
            shape="rounded"
            onChange={onPageChange}
          />
        </div>
      </div>
    </ContainerBox>
  );
};
export default UsersPage;

const ResetPassword = ({ userName, onCloseHandler, setNewPasswordModal }) => {
  const [resetLoader, setResetLoader] = useState(false);

  const { register, setValue, handleSubmit, watch } = useForm({});
  const [error, setError] = useState(false);
  const passwordWatch = watch("password");

  const [type, setType] = useState("password");
  const [conFirmType, setConFirmType] = useState("password");

  const testValue = (event) => {
    if (passwordWatch !== event.target.value) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const onResetHandler = async (data) => {
    let body = {
      username: userName,
      password: data.password,
      // user_password: oldPassword,
    };
    setResetLoader(true);
    let res = await api(APIS.force_change_password, "POST", body);
    if (res?.status === 200) {
      console.log("res", res);
      onCloseHandler();
      toast.success("Reset successful", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      setResetLoader(false);
    } else {
      toast.error(res?.data?.error, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      setResetLoader(false);
    }
  };

  return (
    <div className="resetPassword-container">
      <div
        style={{ position: "absolute", top: 0, right: 0, cursor: "pointer" }}
        onClick={onCloseHandler}
      >
        <CgClose />
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 20,
            width: "25vw",
          }}
        >
          Are you sure you want to Reset?
          <div>
            <div className="form">
              <label>Username</label>
              <div className="passowrd-container">{userName}</div>
            </div>
            <div className="form">
              <label>New Password</label>
              <div className="passowrd-container">
                <input
                  name="password"
                  type={type}
                  ref={register({ required: true })}
                />
                <div className="passowrd-eye">
                  {type === "password" ? (
                    <IoMdEye onClick={() => setType("text")} size={24} />
                  ) : (
                    <IoMdEyeOff onClick={() => setType("password")} size={24} />
                  )}
                </div>
              </div>
            </div>
            <div className="form">
              <label>Confirm Password</label>
              <div className="passowrd-container">
                <input
                  name="passwordConfirm"
                  type={conFirmType}
                  ref={register({
                    validate: (value) =>
                      value === passwordWatch || "The passwords do not match",
                  })}
                  onChange={(e) => testValue(e)}
                />
                <div className="passowrd-eye">
                  {conFirmType === "password" ? (
                    <IoMdEye onClick={() => setConFirmType("text")} size={24} />
                  ) : (
                    <IoMdEyeOff
                      onClick={() => setConFirmType("password")}
                      size={24}
                    />
                  )}
                </div>
              </div>
              {error && (
                <p style={{ color: "red" }}>The passwords do not match</p>
              )}
            </div>
          </div>
          <div
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button color="secondary" type="button" onClick={onCloseHandler}>
              Cancel
            </Button>
            {resetLoader ? (
              <CircularProgress />
            ) : (
              <Button
                type="submit"
                color="default"
                style={{ background: "red", color: "white" }}
                // onClick={() => onResetHandler()}
                onClick={handleSubmit(onResetHandler)}
              >
                {" "}
                reset{" "}
              </Button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};
