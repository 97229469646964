import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigation, useAuth } from "react-auth-navigation";
import moment from "moment";
import { toast } from "react-toastify";
import ContainerBox from "../../common/hoc/ContainerBox.hoc";
import { Button } from "@material-ui/core";
import { api } from "../../../helpers/Helpers";
import { APIS } from "../../../config/Api.config";
import { ActivityIndicator } from "../../common/hoc";
import Header from "../../common/header/Header.common";

const CommentPage = (props) => {
  const { params } = useNavigation();
  const { userId } = useAuth();

  const { register, handleSubmit, setValue } = useForm();

  const [ticketDetail, setTicketDetail] = useState();
  const [fetchTicketDetail, setFetchTicketDetail] = useState(false);

  const [commentLoader, setCommentLoader] = useState(false);

  const fetchDetail = async () => {
    let ticket_detail = await api(APIS.ticket + `${params.id}`);

    if (ticket_detail.status === 200) {
      setTicketDetail(ticket_detail?.data);
    }
  };
  useEffect(() => {
    fetchDetail();
  }, [fetchTicketDetail]);

  const onSubmitComment = async (data) => {
    setCommentLoader(true);

    let body = {
      comment_text: data.comment,
      commented_by: userId,
      ticket: ticketDetail.id,
    };
    let commentResponse = await api(APIS.ticket_comment, "POST", body);
    if (commentResponse.status === 201) {
      setCommentLoader(false);
      setValue("comment", "");
      toast.success("Comment Added!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      fetchDetail();
    }
    setCommentLoader(false);
  };

  return (
    <ContainerBox>
      <Header title="Ticket Comment" />

      <div>
        {ticketDetail ? (
          <div className="addticket-comment">
            Comments
            {ticketDetail?.ticket_events?.filter(
              ({ event_type }) => event_type === 1
            ).length > 0 && (
              <ul className="addticket-comment-show">
                {ticketDetail?.ticket_events
                  .filter(({ event_type }) => event_type === 1)
                  ?.map(({ ticket_event_comment, actor }, index) => {
                    return (
                      <li
                        key={index}
                        style={{ listStyle: "none" }}
                        className="addticket-comment-show-list"
                      >
                        {Number(actor) === Number(userId) ? (
                          <div className="addticket-comment-show-list-comment">
                            <div></div>
                            <div className="right">
                              <div
                                className="addticket-comment-show-list-comment-msg"
                                style={{ background: "#e5e5e5" }}
                              >
                                {ticket_event_comment[0]?.comment_text}
                              </div>
                              <span className="addticket-comment-show-list-comment-date">
                                {moment(
                                  ticket_event_comment[0]?.created_at
                                ).format("YYYY-MM-DD")}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="addticket-comment-show-list-comment">
                            <div className="left">
                              <div
                                className="addticket-comment-show-list-comment-msg"
                                style={{
                                  background: "#52C8FB",
                                  color: "white",
                                }}
                              >
                                {ticket_event_comment[0]?.comment_text}
                              </div>
                              <span className="addticket-comment-show-list-comment-date">
                                {moment(
                                  ticket_event_comment[0]?.created_at
                                ).format("YYYY-MM-DD")}
                              </span>
                            </div>
                            <div />
                          </div>
                        )}
                      </li>
                    );
                  })}
              </ul>
            )}
            <form onSubmit={handleSubmit(onSubmitComment)}>
              Add Comment
              <div className="form">
                <textarea
                  name="comment"
                  ref={register}
                  rows="6"
                  cols="50"
                  placeholder="Comment"
                />
              </div>
              <div className="addgxsite-form-row">
                <ActivityIndicator animating={commentLoader}>
                  <Button type="submit" variant="contained" lor="primary">
                    {" "}
                    Comment
                  </Button>
                </ActivityIndicator>
              </div>
            </form>
          </div>
        ) : (
          <div>Loading</div>
        )}
      </div>
    </ContainerBox>
  );
};

export default CommentPage;
