// CREATE ROLES FOR USER
export const ROLE_ADMIN = "admin";
export const ROLE_USER = "user";

// (('1', 'Admin'),
// ('2', 'Central'),
// ('3','Engineer'),
// ('4', 'Provincial'),
// ('5', 'District'),
// ('6', 'GX_Sites'))

// SET ACCESS ROUTES FOR ALL ROLES
export const USER_ROLES = {
  1: {
    access: [
      "/",
      "/log-in",
      "/sign-in",
      "/dashboard",
      "/dashboard/:id",
      "/home",
      "/machine-status/*",
      // "/machine-status/running-today",
      // "/machine-status/delay-seven",
      // "/machine-status/delay-five-six",
      // "/machine-status/delay-three-four",
      // "/machine-status/delay-one-two",
      "/sites",
      "/gx-machine",
      "/result",
      "/covid-result",
      "/ultra-result",
      "/xdr-result",
      "/ticket",
      "/gx-machine/add",
      "/gx-machine/edit/:id",
      "/sites/add",
      "/sites/edit/:id",
      "/machine-status/edit/:id",
      "/ticket/add",
      "/ticket/edit/:id",
      "/ticket/view/:id",
      "/users",
      "/users/add",
      "/users/edit/:id",
      "/users/:id",
      "/sites/:id",
      "/password/change",
      "/password/reset/",
      "/notification",
      "/notification-threshold",
      "/report/*",
      "/working-module-status",
    ],
  },
  [ROLE_USER]: {
    access: ["/", "/log-in", "/password/reset/", "/sign-in"],
  },

  // ('2', 'Central'),
  2: {
    access: [
      "/",
      "/log-in",
      "/sign-in",
      "/dashboard",
      "/dashboard/:id",
      "/home",
      "/machine-status",
      "/sites",
      "/gx-machine",
      "/result",
      "/covid-result",
      "/ultra-result",
      "/xdr-result",
      "/ticket",
      "/sites/:id",
      "/users/:id",
      "/password/change",
      "/password/reset/",
      "/notification",
      // "/notification-threshold",
      "/report/*",
      "/working-module-status",
    ],
  },

  // ('3','Engineer'),
  3: {
    access: [
      "/",
      "/log-in",
      "/sign-in",
      "/dashboard",
      "/dashboard/:id",
      "/home",
      "/machine-status/*",
      // "/machine-status",
      // "/machine-status/running-today",
      // "/machine-status/delay-seven",
      // "/machine-status/delay-five-six",
      // "/machine-status/delay-three-four",
      // "/machine-status/delay-one-two",
      "/sites",
      "/sites/add",
      "/sites/edit/:id",
      "/gx-machine",
      "/gx-machine/add",
      "/gx-machine/edit/:id",
      "/result",
      "/covid-result",
      "/ultra-result",
      "/xdr-result",
      "/ticket",
      "/machine-status/edit/:id",
      "/ticket/add",
      "/ticket/edit/:id",
      "/ticket/view/:id",
      "/sites/:id",
      // "/users/:id",

      "/users",
      "/users/add",
      "/users/edit/:id",
      "/users/:id",

      "/password/change",
      "/password/reset/",
      "/notification",
      // "/notification-threshold",
      "/report/*",
      "/working-module-status",
    ],
  },

  // ('4', 'Provincial'),
  4: {
    access: [
      "/",
      "/log-in",
      "/sign-in",
      "/dashboard",
      "/dashboard/:id",
      "/home",
      "/machine-status",
      "/machine-status/running-today",
      "/machine-status/delay-seven",
      "/machine-status/delay-five-six",
      "/machine-status/delay-three-four",
      "/machine-status/delay-one-two",
      "/sites",
      "/gx-machine",
      "/result",
      "/covid-result",
      "/ultra-result",
      "/xdr-result",
      "/ticket",
      "/sites/:id",
      "/users/:id",
      "/password/change",
      "/password/reset/",
      "/notification",
      // "/notification-threshold",
      "/report/*",
      "/working-module-status",
    ],
  },

  // ('5', 'District'),
  5: {
    access: [
      "/",
      "/log-in",
      "/sign-in",
      "/dashboard",
      "/dashboard/:id",
      "/home",
      "/machine-status",
      "/machine-status/running-today",
      "/machine-status/delay-seven",
      "/machine-status/delay-five-six",
      "/machine-status/delay-three-four",
      "/machine-status/delay-one-two",
      "/sites",
      "/gx-machine",
      "/result",
      "/covid-result",
      "/ultra-result",
      "/xdr-result",
      "/ticket",
      "/sites/:id",
      "/users/:id",
      "/password/change",
      "/password/reset/",
      "/notification",
      "/report/*",
      "/working-module-status",
    ],
  },

  // ('6', 'GX_Sites'))
  6: {
    access: [
      "/",
      "/log-in",
      "/sign-in",
      "/dashboard",
      "/dashboard/:id",
      "/home",
      "/machine-status/*",
      // "/machine-status",
      // "/machine-status/running-today",
      // "/machine-status/delay-seven",
      // "/machine-status/delay-five-six",
      // "/machine-status/delay-three-four",
      // "/machine-status/delay-one-two",
      "/sites",
      "/gx-machine",
      "/result",
      "/covid-result",
      "/ultra-result",
      "/xdr-result",
      "/ticket",
      "/machine-status/edit/:id",
      "/ticket/add",
      "/ticket/comment/:id",
      "/ticket/view/:id",
      "/sites/:id",
      "/users/:id",
      "/password/change",
      "/password/reset/",
      "/notification",
      "/report/*",
      "/working-module-status",
    ],
  },
};
