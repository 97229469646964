import React, { useState, useEffect } from "react";

import { useNavigation } from "react-auth-navigation";

import { APIS } from "../../../config/Api.config";
import { api } from "../../../helpers/Api.helper";
import ContainerBox from "../../common/hoc/ContainerBox.hoc";
import Header from "../../common/header/Header.common";
import EditGxMachine from "./EditGxMachine";

const EditComponent = () => {
  const { params } = useNavigation();
  const [siteDetailData, setSiteDetailData] = useState();

  const [detail, setDetail] = useState();

  useEffect(() => {
    let mount = true;
    if (params.id) {
      const fetch = async () => {
        let machineDetail = await api(APIS.machine_response + params.id + "/");
        if (mount) {
          setDetail(machineDetail?.data);
        }
      };
      fetch();
    }
    return () => {
      mount = false;
    };
  }, [params.id]);

  return (
    <ContainerBox>
      <Header title="Edit Gx Machine" />
      {detail ? <EditGxMachine editData={detail} /> : <div>Loading...</div>}
    </ContainerBox>
  );
};

export default EditComponent;
