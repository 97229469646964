import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";

import { useNavigation, useAuth } from "react-auth-navigation";
import { useForm } from "react-hook-form";
import ContainerBox from "../common/hoc/ContainerBox.hoc";
import { APIS } from "../../config/Api.config";
import { api } from "../../helpers/Api.helper";
import { Button, Paper } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import Header from "../common/header/Header.common";
// import {ActivityIndicator} from "../common/hoc";
import { Modal } from "react-uicomp";
import { useComponentDidUpdate, useDebounceValue } from "../../hooks";
import { SelectField } from "../common/selectField/selectfield.common";

const ACTIVE_STATUS = [
  { name: "ALL", id: "all" },
  { name: "Active", id: "active" },
  { name: "Inactive", id: "inactive" },
];
const GxmachinePage = () => {
  const { navigation, location } = useNavigation();
  const { routes, navigate } = navigation;
  const { userRole } = useAuth();
  const { register, handleSubmit, watch } = useForm();

  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const [pageNumber, setPageNumber] = useState(query.get("page") || 1);

  const [tableDataArray, setTableDataArray] = useState();
  const [siteList, setSiteList] = useState([]);
  // const [search, setSearch] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [siteValue, setSiteValue] = useState(query.get("site") || "");
  const [machineCode, setMachineCode] = useState(
    query.get("machinecode") || ""
  );
  const [moduleId, setModuleId] = useState(query.get("module") || "");
  const [machineStatus, setMachineStatus] = useState(query.get("status") || "");
  const searchModule = useDebounceValue(moduleId);

  const [visible, setVisible] = useState(false);
  const [machineId, setMachineId] = useState();

  useEffect(() => {
    (async () => {
      let site_list_result = await api(APIS.site_list).catch((error) => {
        console.log(site_list_result.error);
      });
      setSiteList(site_list_result?.data);
    })();
    // fetchSiteList();
  }, []);

  const fetch = async (newPage = 1) => {
    setLoading(true);
    navigate(`${location.pathname}?page=${newPage}`);
    let machine_result = await api(APIS.machine_response + `?page=${newPage}`);
    window.scrollTo(0, 0);
    if (machine_result?.status === 200) {
      setTableDataArray(machine_result?.data);
      setLoading(false);
    }
    setLoading(false);
  };

  useComponentDidUpdate(() => {
    if (pageNumber === 1) {
      siteValue || !!machineCode || !!searchModule || !!machineStatus
        ? searchResult(
            siteValue,
            machineCode,
            searchModule,
            machineStatus,
            false,
            1
          )
        : fetch(1);
    } else {
      setPageNumber(1);
    }
  }, [siteValue, machineCode, searchModule, machineStatus]);

  useEffect(() => {
    siteValue || !!machineCode || !!searchModule || !!machineStatus
      ? searchResult(
          siteValue,
          machineCode,
          searchModule,
          machineStatus,
          false,
          pageNumber
        )
      : fetch(pageNumber);
  }, [pageNumber]);

  const toEditpage = (datas) => {
    navigate({
      pathname: routes["GX Machine"].path + "/edit/" + datas.id,
      state: datas,
    });
  };

  const todeleteHandler = async () => {
    const req = await api(APIS.machine_response + machineId, "DELETE");
    window.location.reload();
  };

  const openDeleteModel = (datas) => {
    setVisible(true);
    setMachineId(datas.id);
    // console.log("rowdata",user)
  };

  const page = async (event, newPage = 1) => {
    setPageNumber(newPage);
  };

  const searchResult = async (
    bodySiteId,
    bodyMachine,
    bodymoduleId,
    bodyMachineStatus,
    loader,
    newPage = 1
  ) => {
    loader && setSearchLoader(true);
    setLoading(true);

    let filter = "";
    if (!!bodySiteId) {
      filter += `&site=${bodySiteId}`;
    }
    if (!!bodyMachine) {
      filter += `&machinecode=${bodyMachine}`;
    }

    if (!!bodymoduleId) {
      filter += `&module=${bodymoduleId}`;
    }

    if (!!bodyMachineStatus) {
      filter += `&status=${bodyMachineStatus}`;
    }

    navigate(`${location.pathname}?page=${newPage}${filter}`);

    let machine_result = await api(
      APIS.machine_response + `search/?page=${newPage}`,
      "POST",
      {
        site_id: bodySiteId,
        machine_id: bodyMachine,
        module_no: bodymoduleId,
        machine_active: bodyMachineStatus,
      }
    );
    window.scrollTo(0, 0);
    if (machine_result?.status) {
      setTableDataArray(machine_result?.data);
      setLoading(false);
    }
    setSearchLoader(false);
    setLoading(false);
  };

  return (
    <ContainerBox>
      <Header title="Gx Machines" />
      <Modal visible={visible} onClose={() => setVisible(false)}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          Are you sure you want to delete?
          <div
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="default"
              style={{ background: "red", color: "white" }}
              onClick={() => todeleteHandler()}
            >
              {" "}
              Delete{" "}
            </Button>
            <Button color="secondary" onClick={() => setVisible(false)}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      <div className="gxmachine">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {(userRole === "1" || userRole === "3") && (
            <div>
              <Button
                onClick={() => navigate(routes["Add GxMachine"].path)}
                variant="contained"
                color="primary"
              >
                Add GX Machine
              </Button>
            </div>
          )}
          {userRole !== "1" && <div />}
          <div className="gxmachine-select">
            <div className=" searchform">
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  marginRight: 10,
                  rowGap: 10,
                  columnGap: 10,
                }}
              >
                <SelectField
                  options={siteList}
                  getOptionLabel="name"
                  getOptionValue="id"
                  onChangeValue={(item) => {
                    console.log("siteData", item);
                    setSiteValue(item?.id);
                  }}
                  value={siteList?.find(
                    (item) => Number(item.id) === Number(siteValue)
                  )}
                  placeholder="Search By Site"
                  isClearable={true}
                  isSearchable={true}
                />
                {/* <select
                  onChange={(e) => setSiteValue(e.target.value)}
                  style={{ marginBottom: 15 }}
                >
                  <option value="">Search By Site</option>
                  {siteList?.length > 0 &&
                    siteList.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.name}
                        </option>
                      );
                    })}
                </select> */}

                <SelectField
                  options={
                    siteList?.find(
                      (result) => result.id === parseInt(siteValue)
                    )?.machines
                  }
                  getOptionLabel="code"
                  getOptionValue="id"
                  onChangeValue={(item) => {
                    console.log("siteData", item);
                    setMachineCode(item?.id);
                  }}
                  value={siteList
                    ?.find((result) => result.id === parseInt(siteValue))
                    ?.machines?.find(
                      (item) => Number(item.id) === Number(machineCode)
                    )}
                  placeholder="Search By Machine"
                  isClearable={true}
                  isSearchable={true}
                />

                {/* {siteList?.length > 0 ? (
                  <select
                    onChange={(e) => setMachineCode(e.target.value)}
                    style={{ marginBottom: 15 }}
                  >
                    <option value="">Search By Machine</option>
                    {siteValue &&
                      siteList
                        ?.find((result) => result.id === parseInt(siteValue))
                        ?.machines.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {item.code}
                            </option>
                          );
                        })}
                  </select>
                ) : (
                  <select
                    onChange={(e) => setMachineCode(e.target.value)}
                    style={{ marginBottom: 15 }}
                  >
                    <option value="">Search By Machine</option>
                  </select>
                )} */}

                <input
                  placeholder="Module"
                  onChange={(e) => setModuleId(e.target.value)}
                />
                {(userRole === "1" || userRole === "2" || userRole === "3") && (
                  <SelectField
                    options={ACTIVE_STATUS}
                    getOptionLabel="name"
                    getOptionValue="id"
                    onChangeValue={(item) => {
                      setMachineStatus(item?.id);
                    }}
                    value={ACTIVE_STATUS.find(
                      (item) => Number(item.id) === Number(machineCode)
                    )}
                    placeholder="Active Status"
                    isClearable={true}
                    isSearchable={true}
                  />

                  // <select
                  //   name="activeStatus"
                  //   ref={register}
                  //   onChange={(e) => setMachineStatus(e.target.value)}
                  //   style={{ marginBottom: 15 }}
                  // >
                  //   <option value="">Active Status</option>
                  //   <option value="all">ALL</option>
                  //   <option value="active">Active</option>
                  //   <option value="inactive">Inactive</option>
                  // </select>
                )}
              </div>

              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: 10,
                }}
              >
                // <ActivityIndicator animating={searchLoader}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ marginBottom: 15 }}
                  >
                    search
                  </Button>
                // </ActivityIndicator>
              </div> */}
            </div>
          </div>
        </div>
        <MaterialTable
          title="GX MACHINES LIST"
          columns={[
            { title: "Code", field: "code" },
            {
              title: "Serial No.",
              field: "serial_name",
              render: (rowData) =>
                rowData.serials.map((serial) => serial.serial_no).toString(),
            },
            { title: "Site", field: "site_name" },
            { title: "No. of Modules", field: "no_of_module" },
          ]}
          data={tableDataArray?.results}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          isLoading={loading}
          actions={[
            (rowData) => {
              return (
                (userRole === "1" || userRole === "3") && {
                  icon: "edit",
                  tooltip: "Edit",
                  onClick: (event, rowData) => {
                    toEditpage(rowData);
                  },
                }
              );
            },

            (rowData) => {
              return (
                (userRole === "1" || userRole === "3") && {
                  icon: "delete",
                  tooltip: "Delete",
                  onClick: (event, rowData) => {
                    openDeleteModel(rowData);
                  },
                }
              );
            },
          ]}
          localization={{
            header: {
              actions: userRole === "1" || userRole === "3" ? "Action" : null,
            },
          }}
          options={{
            actionsColumnIndex: -1,
            emptyRowsWhenPaging: false,
            search: false,
            paging: false,
            toolbar: false,
            // pageSizeOptions:[5,10],
            // pageSize:10
          }}
        />
        <div style={{ width: "100%", display: "flex" }}>
          <Pagination
            style={{
              marginLeft: "auto",
              marginTop: 20,
              display: "inline-block",
            }}
            count={Math.ceil(tableDataArray?.count / 10)}
            page={Number(pageNumber)}
            boundaryCount={1}
            variant="outlined"
            shape="rounded"
            onChange={page}
          />
        </div>
      </div>
    </ContainerBox>
  );
};

export default GxmachinePage;
