import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { api } from "../../helpers/Api.helper";
import { APIS } from "../../config/Api.config";
import ContainerBox from "../common/hoc/ContainerBox.hoc";
import DisplayDataBox from "./displayDataBox/DisplayDataBox";
import Header from "../common/header/Header.common";

const MachineStatusPage = () => {
  const [machineStatus, setMachineStatus] = useState();
  const [machineRunning, setMachineRunning] = useState();
  const [machineStatusLoading, setMachineStatusLoading] = useState(false);
  const [machineRunningLoading, setMachineRunningLoading] = useState(false);

  const fetch = async () => {
    let machine_status_response = await api(APIS.machine_status_result);
    setMachineStatus(machine_status_response?.data);
  };

  const runningMachine = async () => {
    setMachineRunningLoading(true);
    let running_machine_response = await api(APIS.machine_online);
    if (running_machine_response.status === 200) {
      setMachineRunning(running_machine_response?.data);
      setMachineRunningLoading(false);
    }
    setMachineRunningLoading(false);
  };
  useEffect(() => {
    runningMachine();
  }, []);

  useEffect(() => {
    let mount = true;
    const initialFetch = async () => {
      setMachineStatusLoading(true);
      let machine_status_response = await api(APIS.machine_status_result);
      if (mount && machine_status_response?.status === 200) {
        setMachineStatus(machine_status_response?.data);
        setMachineStatusLoading(false);
      }
      setMachineStatusLoading(false);
    };

    initialFetch();
    return () => {
      mount = false;
    };
  }, []);

  const onTicketCreate = () => {
    toast.success("Ticket Created Successfully!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
    fetch();
    runningMachine();
  };

  return (
    <ContainerBox>
      <Header title="Machine Status" />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // gridTemplateColumns: "repeat(auto-fit, minmax(500px, 1fr))",
          // columnGap: 20,
          rowGap: 20,
        }}
      >
        <DisplayDataBox
          data={machineRunning}
          title="Machine Running Today"
          toast={onTicketCreate}
          loading={machineRunningLoading}
        />
        <DisplayDataBox
          data={machineStatus?.delay_7_days}
          title="Delay More than 7 days"
          toast={onTicketCreate}
          loading={machineStatusLoading}
        />
        <DisplayDataBox
          data={machineStatus?.delay_5_6_days}
          title="Delay for 5-6 days"
          toast={onTicketCreate}
          loading={machineStatusLoading}
        />
        <DisplayDataBox
          data={machineStatus?.delay_3_4_days}
          title="Delay for 3-4 days"
          toast={onTicketCreate}
          loading={machineStatusLoading}
        />
        <DisplayDataBox
          data={machineStatus?.delay_1_2_days}
          title="Delay for 1-2 days"
          toast={onTicketCreate}
          loading={machineStatusLoading}
        />
      </div>
    </ContainerBox>
  );
};

export default MachineStatusPage;
