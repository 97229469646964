import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export const ReportGraphBox = (props) => {
  const { data } = props;
  const [list, setList] = useState([]);

  const [opacity, setOpacity] = useState({
    invalid_test_rate: 1,
    error_rate: 1,
    positivity_rate: 1,
    rr_rate: 1,
    utilization_rate: 1,
    gx_functional_rate: 1,
  });

  useEffect(() => {
    if (list.length > 0) {
      const ddd = {
        invalid_test_rate: 0.1,
        error_rate: 0.1,
        positivity_rate: 0.1,
        rr_rate: 0.1,
        utilization_rate: 0.1,
        gx_functional_rate: 0.1,
      };
      list.forEach((item) => {
        ddd[item] = 1;
      });
      setOpacity(ddd);
    } else {
      setOpacity({
        invalid_test_rate: 1,
        error_rate: 1,
        positivity_rate: 1,
        rr_rate: 1,
        utilization_rate: 1,
        gx_functional_rate: 1,
      });
    }
  }, [list]);

  const onLegendClick = (item) => {
    const { dataKey } = item;

    const listClone = [...list];
    const isActive = listClone.includes(dataKey);
    if (isActive) {
      setList(listClone.filter((element) => element !== dataKey));
    } else {
      if (listClone.length === 5) {
        setList([]);
      } else {
        setList([...listClone, dataKey]);
      }
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <ResponsiveContainer height={500}>
        <LineChart
          // width={width}
          // height={height}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="name" />
          <YAxis />
          {/* <Tooltip /> */}
          <Tooltip content={<CustomTooltip />} />
          <Legend
            iconType="square"
            onClick={onLegendClick}
            content={<CustomizedLegend />}
            activeList={list}
          />
          <Line
            type="monotone"
            dataKey="error_rate"
            strokeOpacity={opacity.error_rate}
            stroke="#003f5c"
            strokeWidth={2}
            activeDot={{ r: 6 }}
          />
          <Line
            type="monotone"
            dataKey="gx_functional_rate"
            strokeOpacity={opacity.gx_functional_rate}
            stroke="#05c46b"
            strokeWidth={2}
            activeDot={{ r: 6 }}
          />
          <Line
            type="monotone"
            dataKey="invalid_test_rate"
            strokeOpacity={opacity.invalid_test_rate}
            stroke="#890a0d"
            strokeWidth={2}
            activeDot={{ r: 6 }}
          />
          <Line
            type="monotone"
            dataKey="positivity_rate"
            strokeOpacity={opacity.positivity_rate}
            stroke="#F79F1F"
            strokeWidth={2}
            activeDot={{ r: 6 }}
          />
          <Line
            type="monotone"
            dataKey="rr_rate"
            strokeOpacity={opacity.rr_rate}
            stroke="#0fbcf9"
            strokeWidth={2}
            activeDot={{ r: 6 }}
          />
          <Line
            type="monotone"
            dataKey="utilization_rate"
            strokeOpacity={opacity.utilization_rate}
            stroke="#EE5A24"
            strokeWidth={2}
            activeDot={{ r: 6 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

const CustomizedLegend = (props) => {
  const { payload, onClick, activeList } = props;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
      }}
    >
      {payload.map((entry, index) => (
        <div
          key={`item-${index}`}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            opacity:
              !!activeList?.length > 0 && !activeList.includes(entry.value)
                ? 0.3
                : 1,
          }}
          onClick={() => onClick(entry)}
        >
          <div
            style={{
              height: 20,
              width: 20,
              background: entry.color,
              marginRight: 4,
            }}
          ></div>
          <div>{parseTitle(entry.value)}</div>
        </div>
      ))}
    </div>
  );
};
const parseTitle = (text) => {
  const ddd = text?.toUpperCase().split("_").join(" ");
  // const sss=ddd.map((item)=>capita)
  return ddd;
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "white",
          padding: 10,
          border: "1px solid black",
        }}
      >
        <p className="label">Date: {label}</p>
        {payload?.map(({ dataKey, value, color }, index) => {
          return (
            <p style={{ color: color, padding: 2, marginTop: 2 }} key={index}>
              {parseTitle(dataKey)}: {value}
            </p>
          );
        })}
      </div>
    );
  }

  return null;
};
