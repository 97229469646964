import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useNavigation, useAuth } from "react-auth-navigation";
import { CSSTransition } from "react-transition-group";
import { TbReportAnalytics } from "react-icons/all";
import GXLOGO from "../../../assets/icons/gx_logo.png";
import { api } from "../../../helpers/Api.helper";
import { APIS } from "../../../config/Api.config";
// IMPORT ICONS
import {
  RiDashboardLine,
  RiNotificationLine,
  RiRoadMapLine,
  RiTicketLine,
  GrStatusInfo,
} from "react-icons/all";
import { GoNote } from "react-icons/go";
import { GrInbox } from "react-icons/gr";
import { FiUsers } from "react-icons/fi";
import { useNotification } from "../../../context";
const SELECT_ICONS = {
  Dashboard: <RiDashboardLine />,
  "Machine Status": <GrStatusInfo />,
  "GX Sites": <RiRoadMapLine />,
  Tickets: <RiTicketLine />,
  Results: <GoNote />,
  "GX Machines": <GrInbox />,
  Users: <FiUsers />,
  Notification: <RiNotificationLine />,
  Report: <TbReportAnalytics />,

  "Module Status": <TbReportAnalytics />,
};

const Sidenav = () => {
  const { notificationCount } = useNotification();
  const { navigation } = useNavigation();
  const { routes } = navigation;
  const { userId } = useAuth();
  const [isMtb, setIsMtb] = useState();
  const [isCovid, setIsCovid] = useState();

  const fetchUserData = async () => {
    let res = await api(APIS.users + userId + "/");
    console.log("User Data here", res.data);
    setIsMtb(res.data.is_mtb_results);
    setIsCovid(res.data.is_covid_results);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <div className="sidenav-container">
      <div className="sidenav">
        <ul className="sidenav-nav">
          <div className="sidenav-nav-title" style={{}}>
            <NavLink to="/" activeClassName="sidenav-active">
              <span className="icon">
                <img src={GXLOGO} alt="GX LOGO" />
              </span>
              GXMIS
            </NavLink>
          </div>
          {routes["Dashboard"] && (
            <li className="sidenav-nav-item">
              <NavLink
                to={routes["Dashboard"].path}
                activeClassName="sidenav-active"
              >
                <span className="icon">
                  {SELECT_ICONS[routes["Dashboard"].name]}
                </span>
                {routes["Dashboard"].name}
              </NavLink>
            </li>
          )}
          {routes["Machine Status"] && (
            <li className="sidenav-nav-item">
              <NavLink
                to={routes["Machine Status"].path}
                activeClassName="sidenav-active"
              >
                <span className="icon">
                  {SELECT_ICONS[routes["Machine Status"].name]}
                </span>
                {routes["Machine Status"].name}
              </NavLink>
            </li>
          )}
          {routes["GX Sites"] && (
            <li className="sidenav-nav-item">
              <NavLink
                to={routes["GX Sites"].path}
                activeClassName="sidenav-active"
              >
                <span className="icon">
                  {SELECT_ICONS[routes["GX Sites"].name]}
                </span>
                {routes["GX Sites"].name}
              </NavLink>
            </li>
          )}

          {routes["GX Machine"] && (
            <li className="sidenav-nav-item">
              <NavLink
                to={routes["GX Machine"].path}
                activeClassName="sidenav-active"
              >
                <span className="icon">
                  {SELECT_ICONS[routes["GX Machine"].name]}
                </span>
                {routes["GX Machine"].name}
              </NavLink>
            </li>
          )}
          {isMtb && routes["Results"] && (
            <li className="sidenav-nav-item">
              <NavLink
                to={routes["Results"].path}
                activeClassName="sidenav-active"
              >
                <span className="icon">{SELECT_ICONS.Results}</span>
                {routes["Results"].name}
              </NavLink>
            </li>
          )}
          {isCovid && routes["Covid Results"] && (
            <li className="sidenav-nav-item">
              <NavLink
                to={routes["Covid Results"].path}
                activeClassName="sidenav-active"
              >
                <span className="icon">{SELECT_ICONS.Results}</span>
                {routes["Covid Results"].name}
              </NavLink>
            </li>
          )}
          {routes["Ultra Results"] && (
            <li className="sidenav-nav-item">
              <NavLink
                to={routes["Ultra Results"].path}
                activeClassName="sidenav-active"
              >
                <span className="icon">{SELECT_ICONS.Results}</span>
                {routes["Ultra Results"].name}
              </NavLink>
            </li>
          )}

          {routes["XDR Results"] && (
            <li className="sidenav-nav-item">
              <NavLink
                to={routes["XDR Results"].path}
                activeClassName="sidenav-active"
              >
                <span className="icon">{SELECT_ICONS.Results}</span>
                {routes["XDR Results"].name}
              </NavLink>
            </li>
          )}

          {routes["Tickets"] && (
            <li className="sidenav-nav-item">
              <NavLink
                to={routes["Tickets"].path}
                activeClassName="sidenav-active"
              >
                <span className="icon">
                  {SELECT_ICONS[routes["Tickets"].name]}
                </span>
                {routes["Tickets"].name}
              </NavLink>
            </li>
          )}

          {routes["Users"] && (
            <li className="sidenav-nav-item">
              <NavLink
                to={routes["Users"].path}
                activeClassName="sidenav-active"
              >
                <span className="icon">
                  {SELECT_ICONS[routes["Users"].name]}
                </span>
                {routes["Users"].name}
              </NavLink>
            </li>
          )}

          {routes["Report"] && (
            <li className="sidenav-nav-item">
              <NavLink
                to={routes["Report"].path}
                activeClassName="sidenav-active"
              >
                <span className="icon">
                  {SELECT_ICONS[routes["Report"].name]}
                </span>
                {routes["Report"].name}
              </NavLink>
            </li>
          )}

          {routes["Working Module Status"] && (
            <li className="sidenav-nav-item">
              <NavLink
                to={routes["Working Module Status"].path}
                activeClassName="sidenav-active"
              >
                <span className="icon">
                  {SELECT_ICONS[routes["Working Module Status"].name]}
                </span>
                {routes["Working Module Status"].name}
              </NavLink>
            </li>
          )}

          {routes["Notification"] && (
            <li className="sidenav-nav-item">
              <NavLink
                to={routes["Notification"].path}
                activeClassName="sidenav-active"
              >
                <span className="icon">
                  {SELECT_ICONS[routes["Notification"].name]}
                </span>
                {routes["Notification"].name}{" "}
                {!!notificationCount ? (
                  <span
                    style={{
                      marginLeft: 10,
                      height: 30,
                      // width: 30,
                      padding: "0px 5px",
                      background: "red",
                      borderRadius: 4,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <strong style={{ color: "white" }}>
                      {notificationCount}
                    </strong>
                  </span>
                ) : null}
              </NavLink>
            </li>
          )}
          {routes["NotificationThreshold"] && (
            <li className="sidenav-nav-item">
              <NavLink
                to={routes["NotificationThreshold"].path}
                activeClassName="sidenav-active"
              >
                <span className="icon">
                  {SELECT_ICONS[routes["Notification"].name]}
                </span>
                {routes["NotificationThreshold"].name}
              </NavLink>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default function SideNavigation(props) {
  const { isLoggedIn, sideNavVisible } = useAuth();

  if (isLoggedIn) {
    return (
      <>
        <CSSTransition
          in={sideNavVisible}
          timeout={200}
          classNames="sidenavigation-node"
          unmountOnExit
        >
          <div className="sidenavigation-web">
            <Sidenav />
          </div>
        </CSSTransition>
      </>
    );
  } else {
    return null;
  }
}

// export default Sidenav;
