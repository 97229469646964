import React, { useState, useEffect } from "react";
import { useNavigation } from "react-auth-navigation";
import { useForm } from "react-hook-form";
import { Button, FormControlLabel, Checkbox } from "@material-ui/core";
import ContainerBox from "../../common/hoc/ContainerBox.hoc";

import { APIS } from "../../../config/Api.config";
import { api } from "../../../helpers/Api.helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ActivityIndicator } from "../../common/hoc";
import Header from "../../common/header/Header.common";

const AddGxSite = (props) => {
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const { register, handleSubmit, errors, watch } = useForm();
  const [state, setState] = React.useState({
    enrolledSite: false,
    mtb: false,
    covid: false,
  });
  const [loader, setLoader] = useState(false);
  const [provinceList, setProvinceList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [municipalityList, setMunicipalityList] = useState([]);
  const [wardList, setWardList] = useState([]);
  const [hiList, setHiList] = useState([]);
  const watchProvince = watch("province");
  const watchDistrict = watch("district");
  const watchMunicipality = watch("municipality");
  const watchWard = watch("ward");

  useEffect(() => {
    const fetchProvince = async () => {
      let provinceList = await api(APIS.get_province);
      setProvinceList(provinceList?.data?.results);
    };
    fetchProvince();
  }, []);

  useEffect(() => {
    const fetchDistrict = async () => {
      let districtList = await api(APIS.get_district + watchProvince);
      setDistrictList(districtList?.data);
    };
    if (watchProvince) fetchDistrict();
  }, [watchProvince]);

  useEffect(() => {
    const fetchMunicipality = async () => {
      let municipalityList = await api(APIS.get_municipalites + watchDistrict);
      setMunicipalityList(municipalityList?.data);
    };
    if (watchDistrict) fetchMunicipality();
  }, [watchDistrict]);

  useEffect(() => {
    const fetchWard = async () => {
      let wardList = await api(APIS.get_ward + watchMunicipality);
      setWardList(wardList?.data);
    };
    if (watchMunicipality) fetchWard();
  }, [watchMunicipality]);

  useEffect(() => {
    const fetchHi = async () => {
      let hiList = await api(APIS.get_health_facility + watchWard);
      // console.log("hilist",hiList)
      setHiList(hiList?.data);
    };
    if (watchWard) fetchHi();
  }, [watchWard]);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const cancelHandler = () => {
    window.history.back();
  };

  const onSubmit = async (data) => {
    setLoader(true);
    let body = {
      name: data.name,
      longitude: data.longitude,
      latitude: data.latitude,
      contactName: data.contactName,
      contactPhone: data.contactPhone,
      contactEmail: data.contactEmail,
      province: data.province,
      enrolledSite: data.enrolledSite,
      mtb: data.mtb,
      covid: data.covid,
      // province: data.province,
      district: data.district,
      municipality: data.municipality,
      ward: data.ward,
      hi: data.hi,
    };

    let add_site_response = await api(APIS.sites_response, "POST", body);
    if (add_site_response.status === 201) {
      setLoader(false);
      toast.success("Site Added", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      navigate(routes["GX Sites"].path);
    }
  };

  return (
    <ContainerBox>
      <Header title="Add Gx Site" />
      <div className="addgxsite">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="addgxsite-form">
            {/* <div className="addgxsite-form-row"> */}

            <div className="addgxsite-form-name form">
              <label>Name</label>
              <input
                name="name"
                ref={register({ required: true })}
                className={`${errors.name && "showerror"} `}
              />
            </div>
            {/* </div> */}
            <div className="addgxsite-form-row">
              <div className="addgxsite-form-site form">
                <label>Province</label>
                {/* {provinceList.length>0 && */}
                <select
                  name="province"
                  ref={register({ required: true })}
                  className={`${errors.province && "showerror"}`}
                >
                  <option value="">Select Province </option>
                  {provinceList.length > 0 &&
                    provinceList.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name.substring(2)}
                      </option>
                    ))}
                </select>
                {/* } */}
              </div>

              <div className="addgxsite-form-site form">
                <label>District</label>
                {/* { */}
                <select
                  name="district"
                  ref={register({ required: true })}
                  className={`${errors.district && "showerror"}`}
                >
                  <option value="">Select District </option>
                  {districtList.length > 0 &&
                    districtList.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name.substring(4)}
                      </option>
                    ))}
                </select>
                {/* } */}
              </div>
            </div>

            <div className="addgxsite-form-row">
              <div className="addgxsite-form-site form">
                <label>Municipality</label>
                {/* {municipalityList.length>0&& */}
                <select
                  name="municipality"
                  ref={register({ required: true })}
                  className={`${errors.municipality && "showerror"}`}
                >
                  <option value="">Select Municipality </option>
                  {municipalityList.length > 0 &&
                    municipalityList.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name.substring(6)}
                      </option>
                    ))}
                </select>
                {/* } */}
              </div>

              <div className="addgxsite-form-site form">
                <label>Ward</label>
                {/* {wardList.length>0&& */}
                <select
                  name="ward"
                  ref={register({ required: true })}
                  className={`${errors.ward && "showerror"}`}
                >
                  <option value="">Select Ward </option>
                  {wardList.length > 0 &&
                    wardList.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name.substring(6)}
                      </option>
                    ))}
                </select>
                {/* } */}
              </div>
            </div>
            <div className="addgxsite-form-row">
              <div className="addgxsite-form-site form">
                <label>Health Facility</label>
                {/* {hiList.length>0&& */}
                <select
                  name="hi"
                  ref={register({ required: true })}
                  className={`${errors.hi && "showerror"}`}
                >
                  <option value="">Select Health Facility </option>
                  {hiList.length > 0 &&
                    hiList.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </select>
                {/* } */}
              </div>
            </div>

            <div className="addgxsite-form-row">
              <div className="addgxsite-form-latitude form">
                <label>Latitude</label>
                <input
                  name="latitude"
                  type="number"
                  step="any"
                  ref={register({ required: true })}
                  className={`${errors.latitude && "showerror"} `}
                />
              </div>
              <div className="addgxsite-form-longitude form">
                <label>Longitude</label>
                <input
                  name="longitude"
                  type="number"
                  step="any"
                  ref={register({ required: true })}
                  className={`${errors.longitude && "showerror"} `}
                />
              </div>
            </div>

            <div className="addgxsite-form-row">
              <div className="addgxsite-form-contactname form">
                <label>Contact Name</label>
                <input
                  name="contactName"
                  ref={register()}
                  className={`${errors.contactName && "showerror"} `}
                />
              </div>
              <div className="addgxsite-form-contactphone form">
                <label>Contact Phone</label>
                <input
                  name="contactPhone"
                  type="number"
                  ref={register()}
                  className={`${errors.contactPhone && "showerror"} `}
                />
              </div>
            </div>
            <div className="addgxsite-form-row">
              <div className="addgxsite-form-email form">
                <label>Contact Email</label>
                <input
                  name="contactEmail"
                  ref={register()}
                  className={`${errors.contactEmail && "showerror"} `}
                />
              </div>
            </div>

            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.enrolledSite}
                    onChange={handleChange}
                    name="enrolledSite"
                    inputRef={register}
                  />
                }
                label="Enrolled Site"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.mtb}
                    onChange={handleChange}
                    name="mtb"
                    inputRef={register}
                  />
                }
                label="MTB RIF"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.covid}
                    onChange={handleChange}
                    name="covid"
                    inputRef={register}
                  />
                }
                label="SARS 2 COVID"
              />
            </div>
            <div className="addgxsite-form-row">
              <ActivityIndicator animating={loader}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ background: "#3180A9" }}
                >
                  submit
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={cancelHandler}
                >
                  Cancel
                </Button>
              </ActivityIndicator>
            </div>
          </div>
        </form>
      </div>
    </ContainerBox>
  );
};

export default AddGxSite;
