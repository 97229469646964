import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import { api } from "../../../helpers/Helpers";
import { APIS } from "../../../config/Api.config";
import moment from "moment";

export const SiteWiseRate = () => {
  const [fromDate, setFromDate] = useState(() =>
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(() => moment().format("YYYY-MM-DD"));
  const [siteWiseList, setSiteWiseList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);

  useEffect(() => {
    let mount = true;
    const body = {};
    if (!!fromDate && !!toDate) {
      body["start_date"] = fromDate;
      body["end_date"] = toDate;
      (async () => {
        setLoading(true);
        let res = await api(APIS.site_wise_rate_analysis, "POST", {
          start_date: fromDate,
          end_date: toDate,
        });
        console.log("site_wise_rate_analysis", res);

        if (mount && res.status === 200) {
          setSiteWiseList(res.data);
          setLoading(false);
        }
        setLoading(false);
      })();
    }
    return () => {
      mount = false;
    };
  }, [fromDate, toDate]);

  const onDownloadCSV = async () => {
    const body = {
      fileFormat: "csv",
      fileDownload: true,
    };
    if (!!fromDate && !!toDate) {
      body["start_date"] = fromDate;
      body["end_date"] = toDate;
    }
    setDownloadLoading(true);
    let res = await api(APIS.get_csv_site_wise_rate_analysis, "POST", body);
    console.log("get_csv_site_wise_rate_analysis", res);


    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "site wise rate analysis.csv";
    link.href = URL.createObjectURL(
      new Blob([res.data], { type: "text/csv" })
    );
    link.click();


    setDownloadLoading(false);
  };

  // const updateStartDay = (date) => {
  //   var result = new Date(date);
  //   result.setDate(result.getDate() + 6);

  //   setFromDate(date);
  //   setToDate(format(result, "yyyy-MM-dd"));
  // };
  // const updateEndDays = (date) => {
  //   var result = new Date(date);
  //   result.setDate(result.getDate() - 6);
  //   setFromDate(format(result, "yyyy-MM-dd"));
  //   setToDate(date);
  // };
  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="formWrapper">
          <label>Search by Date Range</label>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              columnGap: 20,
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                columnGap: 20,
                // width: "100%",
                width: 300,
              }}
            >
              <KeyboardDatePicker
                disableToolbar
                label="Date From"
                format="yyyy-MM-dd"
                margin="normal"
                value={fromDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                onChange={(date, value) => {
                  setFromDate(value);
                }}
                maxDate={(() => {
                  const dddd = new Date();
                  const red = dddd.setDate(dddd.getDate() - 1);
                  return red;
                })()}
              />

              <KeyboardDatePicker
                disableToolbar
                label="Date To"
                format="yyyy-MM-dd"
                margin="normal"
                value={toDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                onChange={(date, value) => {
                  setToDate(value);
                }}
                maxDate={new Date()}
              />
            </div>
            <div>
              {downloadLoading?
              <CircularProgress
              size={16}
              
              />
              
              :
              <Button
              type="button"
              onClick={onDownloadCSV}
              size="small"
              style={{ marginLeft: 10 }}
              disabled={loading === true}
                variant="contained"
                color="primary"
                className="button"
                >
                Export CSV
              </Button>
              }
            </div>
          </div>
        </div>
      </MuiPickersUtilsProvider>

      <MaterialTable
        title="GX SITE LIST"
        columns={[
          { title: "Site Name", field: "name" },
          {
            title: "Error Rate",
            field: "error_rate",
            render: (rowData) => (
              <div>
                {rowData.error_rate}
                {typeof rowData.error_rate === "number" ? "%" : null}
              </div>
            ),
          },
          {
            title: "Gx Functional Rate",
            field: "gx_functional_rate",
            render: (rowData) => (
              <div>
                {rowData.gx_functional_rate}
                {typeof rowData.gx_functional_rate === "number" ? "%" : null}
              </div>
            ),
          },
          {
            title: "Invalid Test Rate",
            field: "invalid_test_rate",
            render: (rowData) => (
              <div>
                {rowData.invalid_test_rate}
                {typeof rowData.invalid_test_rate === "number" ? "%" : null}
              </div>
            ),
          },
          {
            title: "Positivity Rate",
            field: "positivity_rate",
            render: (rowData) => (
              <div>
                {rowData.positivity_rate}
                {typeof rowData.positivity_rate === "number" ? "%" : null}
              </div>
            ),
          },
          {
            title: "RR Rate",
            field: "rr_rate",
            render: (rowData) => (
              <div>
                {rowData.rr_rate}
                {typeof rowData.rr_rate === "number" ? "%" : null}
              </div>
            ),
          },
          {
            title: "Utilization Rate",
            field: "utilization_rate",
            render: (rowData) => (
              <div>
                {rowData.utilization_rate}
                {typeof rowData.utilization_rate === "number" ? "%" : null}
              </div>
            ),
          },
        ]}
        data={siteWiseList}
        isLoading={loading}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        options={{
          actionsColumnIndex: -1,
          emptyRowsWhenPaging: false,
          search: false,
          pageSizeOptions: [5, 10, 15],
          pageSize: 10,
          paging: true,
          toolbar: false,
        }}
      />
    </div>
  );
};
