import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigation } from "react-auth-navigation";
import { Button } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import moment from "moment";

import { toast } from "react-toastify";

import { APIS } from "../../../config/Api.config";
import { api } from "../../../helpers/Api.helper";
import ContainerBox from "../../common/hoc/ContainerBox.hoc";
import Header from "../../common/header/Header.common";
import { SelectField } from "../../common/selectField/selectfield.common";

const AddGxMachine = (props) => {
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const { register, handleSubmit, errors, control } = useForm();

  const [siteList, setSiteList] = useState([]);
  const [siteData, setSiteData] = useState();
  const [installationDate, setInstallationDate] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      let site_list_result = await api(APIS.site_list);
      setSiteList(site_list_result?.data);
    };
    fetch();
  }, []);

  const cancelHandler = () => {
    window.history.back();
  };

  const onSubmit = async (data) => {
    let body = {
      code: data.code,
      serial: data.serial ? data.serial : null,
      // warrantyStartDate: moment(data.warrantyStart).format("YYYY-MM-DD"),
      // warrantyEndDate: moment(data.warrantyEndDate).format("YYYY-MM-DD"),
      dateInstalled: moment(data.installationDate).format("YYYY-MM-DD"),
      // calibrationDate: moment(data.caliberation).format("YYYY-MM-DD"),
      site: siteData,
    };
    let machine_post_result = await api(APIS.machine_response, "POST", body);

    if (machine_post_result.status === 201) {
      toast.success("GxMachine Added", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      navigate({ pathname: routes["GX Machine"].path });
    }
  };

  return (
    <ContainerBox>
      <Header title="Add Gx Machine" />
      <div className="addgxmachine">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="addgxmachine-form">
            <div className="addgxmachine-form-site form">
              <label>GX-SITE</label>
              <SelectField
                options={siteList}
                getOptionLabel="name"
                getOptionValue="id"
                onChangeValue={(item) => {
                  setSiteData(item?.id);
                }}
                value={siteList?.find((item) => item.id === siteData)}
                placeholder="Select GxSite"
                isClearable={true}
                isSearchable={true}
              />
              {/* {siteList.length > 0 ? (
                <select
                  name="site"
                  ref={register({ required: true })}
                  className={`${errors.site && "showerror"}`}
                >
                  <option value="">Select Site</option>
                  {siteList.map((item, index) => {
                    return (
                      <option value={item.id} key={index}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <select
                  name="site"
                  ref={register({ required: true })}
                  className={`${errors.site && "showerror"}`}
                >
                  <option value="">Select Site</option>
                </select>
              )} */}
            </div>

            <div className="addgxmachine-form-row">
              <div className="addgxmachine-form-code form">
                <label>Code</label>
                <input
                  name="code"
                  ref={register({ required: true })}
                  className={`${errors.code && "showerror"} `}
                />
              </div>

              <div className="addgxmachine-form-serial form">
                <label>Serial Number</label>
                <input
                  name="serial"
                  ref={register({ required: false })}
                  className={`${errors.serial && "showerror"} `}
                />
              </div>
            </div>

            {/* <div className="addgxmachine-form-row">
              <div className="addgxmachine-form-startdate date">
                <label>GX Warranty Start Date</label>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    name="warrantyStart"
                    control={control}
                    defaultValue={null}
                    rules={{ required: false }}
                    as={
                      <KeyboardDatePicker
                        label="Event date"
                        format="yyyy-MM-dd"
                        margin="normal"
                        value={warrantyStart}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    }
                  />
                  {errors.warrantyStart && (
                    <div style={{ color: "red" }}>
                      <strong>Date is Required!!</strong>
                    </div>
                  )}
                </MuiPickersUtilsProvider>
              </div>
              <div className="addgxmachine-form-startdate date">
                <label>GX Warranty End Date</label>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    name="warrantyEndDate"
                    control={control}
                    defaultValue={null}
                    rules={{ required: false }}
                    as={
                      <KeyboardDatePicker
                        label="Event date"
                        format="yyyy-MM-dd"
                        margin="normal"
                        value={warrantyEnd}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    }
                  />
                  {errors.warrantyEndDate && (
                    <div style={{ color: "red" }}>
                      <strong>Date is Required!!</strong>
                    </div>
                  )}
                </MuiPickersUtilsProvider>
              </div>
            </div> */}

            <div className="addgxmachine-form-row">
              {/* <div className="addgxmachine-form-startdate date">
                <label>Calliberation Date</label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    name="caliberation"
                    control={control}
                    defaultValue={null}
                    rules={{ required: false }}
                    as={
                      <KeyboardDatePicker
                        label="Event date"
                        format="yyyy-MM-dd"
                        margin="normal"
                        value={caliberation}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    }
                  />
                  {errors.caliberation && (
                    <div style={{ color: "red" }}>
                      <strong>Date is Required!!</strong>
                    </div>
                  )}
                </MuiPickersUtilsProvider>
              </div> */}

              <div className="addgxmachine-form-startdate date">
                <label>Installation Date</label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    name="installationDate"
                    control={control}
                    defaultValue={null}
                    rules={{ required: false }}
                    as={
                      <KeyboardDatePicker
                        label="Event date"
                        format="yyyy-MM-dd"
                        margin="normal"
                        value={installationDate}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    }
                  />
                  {errors.installationDate && (
                    <div style={{ color: "red" }}>
                      <strong>Date is Required!!</strong>
                    </div>
                  )}
                </MuiPickersUtilsProvider>
              </div>
            </div>

            <div className="addgxmachine-form-row">
              <Button type="submit" variant="contained" color="primary">
                submit
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={cancelHandler}
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </div>
    </ContainerBox>
  );
};

export default AddGxMachine;
