import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import { api } from "../../../helpers/Helpers";
import { APIS } from "../../../config/Api.config";
import { ReportGraphBox } from "../component";
import moment from "moment";

export const SiteWiseRateGraph = () => {
  const [fromDate, setFromDate] = useState(() =>
    moment().subtract(6, "months").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(() => moment().format("YYYY-MM-DD"));
  const [siteWiseGraphData, setSiteWiseGraphData] = useState([]);
  const [graphLoading, setGraphLoading] = useState(false);

  useEffect(() => {
    let mount = true;
    if (!!fromDate && !!toDate) {
      const body = {
        aggregateData: true,
        start_date: fromDate,
        end_date: toDate,
      };
      // body["start_date"] = fromDate;
      // body["end_date"] = toDate;
      (async () => {
        setGraphLoading(true);
        let res = await api(APIS.site_wise_rate_analysis, "POST", body);
        console.log("site_wise_rate_analysis Graph", res?.data);

        if (mount && res.status === 200) {
          const sitewiseObj = { ...res.data };
          const sitewiseObjArray = Object.keys(sitewiseObj);
          const ddd = sitewiseObjArray.map((item) => {
            return {
              ...sitewiseObj[item],
              name: item,
            };
          });

          console.log("ddddddddd", ddd);
          setSiteWiseGraphData(ddd);
          setGraphLoading(false);
        }
        setGraphLoading(false);
      })();
    }
    return () => {
      mount = false;
    };
  }, [fromDate, toDate]);

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="formWrapper">
          <label>Search by Date Range</label>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              columnGap: 20,
              // width: "100%",
              width: 300,
            }}
          >
            <KeyboardDatePicker
              disableToolbar
              label="Date From"
              format="yyyy-MM-dd"
              margin="normal"
              value={fromDate}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              onChange={(date, value) => {
                setFromDate(value);
              }}
              maxDate={(() => {
                const dddd = new Date();
                const red = dddd.setDate(dddd.getDate() - 1);
                return red;
              })()}
            />

            <KeyboardDatePicker
              disableToolbar
              label="Date To"
              format="yyyy-MM-dd"
              margin="normal"
              value={toDate}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              onChange={(date, value) => {
                setToDate(value);
              }}
              maxDate={new Date()}
            />
          </div>
        </div>
      </MuiPickersUtilsProvider>

      {graphLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : siteWiseGraphData?.length > 0 ? (
        <div style={{ marginTop: 10 }}>
          <ReportGraphBox data={siteWiseGraphData} />
        </div>
      ) : null}
    </div>
  );
};
