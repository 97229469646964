import React from "react";

export const FormLabel = ({ children, label, className }) => {
  return (
    <div className={`formlabel ${className}`}>
      <label>{label}</label>
      {children}
    </div>
  );
};
