import React, { useState, useEffect } from "react";

import { useNavigation } from "react-auth-navigation";

import ContainerBox from "../../common/hoc/ContainerBox.hoc";

import { api } from "../../../helpers/Helpers";
import { APIS } from "../../../config/Api.config";
import Header from "../../common/header/Header.common";
import EditComponent from "./EditComponent";

const EditTicket = (props) => {
  const { params } = useNavigation();

  const [ticketDetail, setTicketDetail] = useState();
  const [fetchTicketDetail, setFetchTicketDetail] = useState(false);

  const goBack = () => {
    props.history.goBack();
  };

  const fetchDetail = async () => {
    let ticket_detail = await api(APIS.ticket + `${params.id}`);

    if (ticket_detail.status === 200) {
      setTicketDetail(ticket_detail?.data);
    }
  };
  useEffect(() => {
    fetchDetail();
  }, [fetchTicketDetail]);

  return (
    <ContainerBox>
      <Header title="Edit Ticket" />

      {ticketDetail ? (
        <EditComponent
          editData={ticketDetail}
          reloadData={fetchDetail}
          goBack={goBack}
        />
      ) : (
        <div>Loading</div>
      )}
    </ContainerBox>
  );
};

export default EditTicket;
